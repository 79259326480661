export default function useExperienceLogic(experience, user) {
	const { orders: userOrders } = user ? user : []

	const userExperienceOrder =
		userOrders?.filter((e) => {
			return e.experience === experience._id
		})[0] ?? []

	const { _id: userOrderId } = userExperienceOrder

	let enableButton = true
	let buttonLabel = 'Not Available'

	//////////    Start Experience Open Status Logic    //////////
	//////////////////////////////////////////////////////////////

	// If open status is forced open
	if (experience?.status === 'Open') {
		enableButton = true
		if (userExperienceOrder?.orderStatus === 'Paid in full') {
			buttonLabel = 'View Order'
		} else if (userExperienceOrder?.orderStatus === 'Deposit paid') {
			buttonLabel = `Pay Remaining ${userExperienceOrder.totalAmountRemaining.toFixed(
				2
			)}`
		} else if (userExperienceOrder.orderStatus === 'open') {
			buttonLabel = 'Complete Your Order'
		} else {
			buttonLabel = `Let's Go`
		}
	}

	// If order is paid in full
	else if (userExperienceOrder.orderStatus === 'Paid in full') {
		enableButton = true
		buttonLabel = 'View Order'
	}

	// If deposit paid and not past final payment deadline
	else if (
		userExperienceOrder.orderStatus === 'Deposit paid' &&
		Date.parse(experience?.finalPaymentDeadline) > Date.now()
	) {
		enableButton = true
		buttonLabel = `Pay remaining ${userExperienceOrder.totalAmountRemaining.toFixed(
			2
		)}`
	}

	// If status is forced to presale or closed or it is not on sale yet or it is past the final payment deadline
	else if (
		experience?.status === 'Presale' ||
		experience?.status === 'Closed' ||
		Date.parse(experience?.onSaleDate) > Date.now() ||
		Date.parse(experience?.finalPaymentDeadline) < Date.now()
	) {
		enableButton = false
		buttonLabel = 'Not Available'
	}

	// If there is a deposit option and we are past the deposit deadline
	else if (
		experience?.depositOption &&
		Date.parse(experience?.depositPaymentDeadline) < Date.now()
	) {
		enableButton = false
		buttonLabel = `Not Available`
	}

	// Else open
	else {
		enableButton = true
		if (userExperienceOrder?.orderStatus === 'Paid in full') {
			buttonLabel = 'View Order'
		} else if (userExperienceOrder?.orderStatus === 'Deposit paid') {
			buttonLabel = `Pay Remaining ${userExperienceOrder.totalAmountRemaining.toFixed(
				2
			)}`
		} else if (userExperienceOrder?.orderStatus === 'open') {
			buttonLabel = 'Complete Your Order'
		} else {
			buttonLabel = `Let's Go`
		}
	}

	// NEW IF: If Experience Status is Closed and there is button override text, and user has no order, use button override text
	if (
		experience?.status === 'Closed' &&
		experience?.buttonOverrideText !== '' &&
		userExperienceOrder?.orderStatus !== 'Paid in full'
	) {
		buttonLabel = experience?.buttonOverrideText
	}

	//NEW IF: If Experience Status is Closed, and user has an order not paid in full, and ForceAllowPayment is true, then allow payment
	if (
		experience?.status === 'Closed' &&
		experience?.forceAllowPaymentOption === true &&
		(userExperienceOrder?.orderStatus === 'Deposit paid' ||
			userExperienceOrder?.orderStatus === 'open') &&
		Date.parse(experience?.finalPaymentDeadline) >= Date.now()
	) {
		enableButton = true
		buttonLabel = 'Complete Your Order'
	}

	////////////////////////////////////////////////////////////
	//////////    End Experience Open Status Logic    //////////

	return { enableButton, buttonLabel, userOrderId }
}
