import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Button, Form } from 'react-bootstrap'
import './Options.css'

function OptionCard(props) {
	const addOns = useSelector((state) => state.flow.orderInfo.addOns)

	const [selectionStatus, setSelectionStatus] = useState(
		addOns.filter(function (addOn) {
			return addOn.tripOptionId === props.tripOptionId
		}).length > 0
	)

	const [buttonTitle, setButtonTitle] = useState(
		selectionStatus ? 'Remove Trip Option' : 'Add to Trip'
	)

	const [size, setSize] = useState(
		addOns.filter(function (addOn) {
			return addOn.tripOptionId === props.tripOptionId
		})[0]?.size || props.showSizing
			? 'Small'
			: undefined
	)

	const submitHandler = (tripOption) => {
		if (selectionStatus) {
			props.removeTripOption(tripOption)
			setSelectionStatus(!selectionStatus)
			setButtonTitle('Add To Trip')
		} else {
			props.addTripOption(tripOption, size)
			setSelectionStatus(!selectionStatus)
			setButtonTitle('Remove Trip Option')
		}
	}

	return (
		<>
			<div
				style={{
					boxShadow: '0px 0px 15px  #f5f5f5',
					margin: '1rem 0 1rem 0',
				}}
			>
				<li className='option_cards__item'>
					<div className='option_cards__item__link'>
						<figure
							className='option_cards__item__pic-wrap'
							data-category={`$${props.price.toFixed(2)}`}
						>
							<img
								className='option_cards__item__img'
								alt={props.title}
								src={props.src}
							/>
						</figure>
						<div className='option_cards__item__info py-2'>
							<h5 className='option_cards__item__text mb-2'>{props.title}</h5>
						</div>
						<p className='m-2'>{props.desc}</p>
					</div>
				</li>
				{!!props.showSizing ? (
					<Form.Group controlId='size' className='m-3'>
						<Form.Label>Size:</Form.Label>
						<Form.Control
							as='select'
							type='text'
							placeholder='Select a size...'
							value={size}
							required={!!props.showSizing}
							onChange={(e) => {
								setSize(e.target.value)
							}}
							disabled={selectionStatus}
						>
							<option>Small</option>
							<option>Medium</option>
							<option>Large</option>
							<option>Extra Large</option>
						</Form.Control>
					</Form.Group>
				) : (
					''
				)}

				<Button
					onClick={() => submitHandler(props)}
					className='btn-block'
					type='button'
				>
					{buttonTitle}
				</Button>
			</div>
		</>
	)
}

export default OptionCard
