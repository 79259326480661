import axios from 'axios'
import {
	LEAD_REGISTER_FAIL,
	LEAD_REGISTER_REQUEST,
	LEAD_REGISTER_SUCCESS,
	LEAD_LIST_EXPERIENCE_REQUEST,
	LEAD_LIST_EXPERIENCE_SUCCESS,
	LEAD_LIST_EXPERIENCE_FAIL,
} from '../constants/leadConstants'

export const register = (email, experience) => async (dispatch) => {
	try {
		dispatch({
			type: LEAD_REGISTER_REQUEST,
		})

		const config = {
			headers: {
				'Content-Type': 'application/json',
			},
		}
		const { data } = await axios.post(
			'/api/leads',
			{
				email,
				experience,
			},
			config
		)

		dispatch({
			type: LEAD_REGISTER_SUCCESS,
			payload: data,
		})
	} catch (error) {
		dispatch({
			type: LEAD_REGISTER_FAIL,
			payload:
				error.response && error.response.data.message
					? error.response.data.message
					: error.message,
		})
	}
}

export const listExperienceLeads =
	(experienceId) => async (dispatch, getState) => {
		try {
			dispatch({
				type: LEAD_LIST_EXPERIENCE_REQUEST,
			})

			const {
				userLogin: { userInfo },
			} = getState()

			const config = {
				headers: {
					Authorization: `Bearer ${userInfo.token}`,
				},
			}

			const { data } = await axios.get(
				`/api/leads/experienceleads/${experienceId}`,
				config
			)

			dispatch({
				type: LEAD_LIST_EXPERIENCE_SUCCESS,
				payload: data,
			})
		} catch (error) {
			dispatch({
				type: LEAD_LIST_EXPERIENCE_FAIL,
				payload:
					error.response && error.response.data.message
						? error.response.data.message
						: error.message,
			})
		}
	}
