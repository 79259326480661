import React, { useEffect } from 'react'
import { LinkContainer } from 'react-router-bootstrap'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Container, Table, Button } from 'react-bootstrap'
import Message from '../components/Message'
import Loader from '../components/Loader'
import UserDetailHeader from '../components/UserDetailHeader'
import { getUserDetails } from '../services/actions/userActions'
import { listUserOrders } from '../services/actions/orderActions'
const ExperienceListOrdersScreen = ({ match, history }) => {
	const userId = match.params.id
	const dispatch = useDispatch()

	const userDetails = useSelector((state) => state.userDetails)
	const { error, user } = userDetails

	const userLogin = useSelector((state) => state.userLogin)
	const { userInfo } = userLogin

	const orderListUser = useSelector((state) => state.orderListUser)
	const { orders } = orderListUser

	useEffect(() => {
		dispatch(listUserOrders(userId))
		dispatch(getUserDetails(userId))
	}, [userId, dispatch])

	useEffect(() => {
		if (!userInfo) {
			history.push('/login')
		} else if (!user.name || !orders) {
			dispatch(getUserDetails(userId))
		}
	}, [dispatch, history, userInfo, user, orders, userId])

	return (
		<>
			<UserDetailHeader />
			<Container className='my-4'>
				<h1>Orders</h1>
				{!orders ? (
					<Loader />
				) : error ? (
					<Message variant='danger'>{error}</Message>
				) : (
					<Table striped bordered hover responsive className='table-sm'>
						<thead>
							<tr>
								<th>Experience</th>
								<th>DATE</th>
								<th>TOTAL PRICE</th>
								<th>TOTAL PAID</th>
								<th>TOTAL REMAINING</th>
								<th></th>
							</tr>
						</thead>
						<tbody>
							{orders.map((order) => (
								<tr key={order._id}>
									<td>
										<Link
											to={`/admin/experience/${order?.experience?._id}/orders`}
										>
											{order.experience.name}
										</Link>
									</td>
									<td>{order.createdAt.substring(0, 10)}</td>
									<td>${order.totalPrice}</td>
									<td>${order.totalAmountPaid}</td>
									<td>${order.totalAmountRemaining}</td>

									<td>
										<LinkContainer to={`/order/${order._id}`}>
											<Button variant='light' className='btn-sm'>
												Details
											</Button>
										</LinkContainer>
									</td>
								</tr>
							))}
						</tbody>
					</Table>
				)}
			</Container>
		</>
	)
}

export default ExperienceListOrdersScreen
