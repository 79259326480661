import React from 'react'
import { Link } from 'react-router-dom'
import { Container, Button } from 'react-bootstrap'

const NotFoundScreen = () => {
	return (
		<Container className='text-center my-4'>
			<h1>404 - Page Not Found</h1>
			<p>Sorry, the page you're looking for doesn't exist.</p>
			<Link to='/'>
				<Button variant='primary'>Go Back Home</Button>
			</Link>
		</Container>
	)
}

export default NotFoundScreen
