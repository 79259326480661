import axios from 'axios'
import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Form, Button, Container } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import Loader from '../components/Loader'
import FormContainer from '../components/FormContainer'
import DetailHeader from '../components/DetailHeader'
import {
	getExperienceDetails,
	updateExperience,
} from '../services/actions/experienceActions'
import { EXPERIENCE_UPDATE_RESET } from '../services/constants/experienceConstants'

const ExperienceEditAccScreen = ({ match, history }) => {
	const experienceId = match.params.id
	const [uploading, setUploading] = useState(false)

	const [accTitle, setAccTitle] = useState('')
	const [accDescription, setAccDescription] = useState('')
	const [accImage, setAccImage] = useState('')

	const dispatch = useDispatch()

	const experienceDetails = useSelector((state) => state.experienceDetails)
	const { loading, error, experience } = experienceDetails

	const experienceUpdate = useSelector((state) => state.experienceUpdate)
	const {
		loading: loadingUpdate,
		error: errorUpdate,
		success: successUpdate,
	} = experienceUpdate

	const userLogin = useSelector((state) => state.userLogin)
	const { userInfo: isAdmin } = userLogin

	useEffect(() => {
		if (!isAdmin) {
			history.push('/')
		} else if (successUpdate) {
			dispatch({ type: EXPERIENCE_UPDATE_RESET })
			history.push('/admin/experienceList')
		} else {
			if (!experience.name || experience._id !== experienceId) {
				dispatch(getExperienceDetails(experienceId))
			} else {
				setAccTitle(experience.accTitle)
				setAccDescription(experience.accDescription)
				setAccImage(experience.accImage)
			}
		}
	}, [experience, experienceId, dispatch, history, successUpdate, isAdmin])

	const uploadFileHandler = async (e) => {
		const file = e.target.files[0]
		const formData = new FormData()
		formData.append('image', file)
		setUploading(true)

		try {
			const config = {
				headers: {
					'Content-type': 'multipart/form/data',
				},
			}
			const { data } = await axios.post('/api/upload', formData, config)

			setAccImage(data)
			setUploading(false)
		} catch (error) {
			console.error.apply(error)
			setUploading(false)
		}
	}

	const submitHandler = (e) => {
		e.preventDefault()
		dispatch(
			updateExperience({
				_id: experienceId,
				accTitle,
				accDescription,
				accImage,
			})
		)
	}
	return (
		<>
			<DetailHeader />
			<Container>
				<Link to='/admin/experiencelist' className='btn btn-light my-3'>
					Go Back
				</Link>
				<FormContainer>
					<h1>Edit Accommodation Details</h1>
					{loadingUpdate && <Loader />}
					{errorUpdate && <Message variant='danger'>{errorUpdate}</Message>}
					{loading ? (
						<Loader />
					) : error ? (
						<Message variant='danger'>{error}</Message>
					) : (
						<Form onSubmit={submitHandler}>
							<Form.Group controlId='accTitle'>
								<Form.Label>Name</Form.Label>
								<Form.Control
									type='text'
									placeholder='Enter name of accommodation'
									value={accTitle}
									onChange={(e) => setAccTitle(e.target.value)}
								></Form.Control>
							</Form.Group>

							<Form.Group controlId='accDescription'>
								<Form.Label>Description</Form.Label>
								<Form.Control
									as='textarea'
									type='text'
									placeholder='Enter description'
									value={accDescription}
									onChange={(e) => setAccDescription(e.target.value)}
									rows={5}
								></Form.Control>
							</Form.Group>

							<Form.Group controlId='accImage'>
								<Form.Label>Image</Form.Label>
								<Form.Control
									type='text'
									placeholder='Enter image url'
									value={accImage}
									onChange={(e) => setAccImage(e.target.value)}
								></Form.Control>
								<Form.File
									id='image-file'
									label='Choose File'
									custom
									onChange={uploadFileHandler}
								></Form.File>
								{uploading && <Loader />}
							</Form.Group>

							<Button type='submit' variant='primary'>
								Update
							</Button>
						</Form>
					)}
				</FormContainer>
			</Container>
		</>
	)
}

export default ExperienceEditAccScreen
