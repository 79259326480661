import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { PayPalButtons } from '@paypal/react-paypal-js'
import { getOrderDetails, payOrder } from '../services/actions/orderActions'
import { ORDER_PAY_RESET } from '../services/constants/orderConstants'

const PaypalCheckoutButton = ({ product, orderId }) => {
	const dispatch = useDispatch()

	const [paidFor, setPaidFor] = useState(false)
	const [error, setError] = useState(null)

	const orderPay = useSelector((state) => state.orderPay)
	const { loading: loadingPay, success: successPay } = orderPay

	useEffect(() => {
		if (successPay) {
			dispatch({ type: ORDER_PAY_RESET })
			dispatch(getOrderDetails(orderId))
		}
	}, [dispatch, orderId, successPay])

	const handleApprove = (paymentResult) => {
		dispatch(payOrder(orderId, paymentResult))
		setPaidFor(true)
	}

	if (paidFor) {
		// Display success message, modal or redirect user to success page
	}

	if (error) {
		// Display error message, modal or redirect user to error page
		alert(error)
	}

	return (
		<PayPalButtons
			style={{
				color: 'gold',
				layout: 'vertical',
				height: 48,
				tagline: false,
				shape: 'rect',
			}}
			onClick={(data, actions) => {
				// Validate on button click, client or server side
				return actions.resolve()
			}}
			createOrder={(data, actions) => {
				return actions.order.create({
					purchase_units: [
						{
							description: product.description,
							amount: {
								value: product.price,
							},
						},
					],

					application_context: {
						shipping_preference: 'NO_SHIPPING',
					},
				})
			}}
			onApprove={async (data, actions) => {
				const paymentResult = await actions.order.capture()
				handleApprove(paymentResult)
			}}
			onCancel={() => {
				// Display cancel message, modal or redirect user to cancel page or back to cart
			}}
			onError={(err) => {
				setError(err)
				console.error('PayPal Checkout onError', err)
			}}
		/>
	)
}

export default PaypalCheckoutButton
