import { DateTime } from 'luxon'

const displayExperienceDate = (date, format) => {
	const getNumberSuffix = (num) => {
		const th = 'th'
		const rd = 'rd'
		const nd = 'nd'
		const st = 'st'

		let lastTwo = num.toString().slice(-2)

		if (lastTwo == 11 || lastTwo == 12 || lastTwo == 13) return th

		let lastDigit = num.toString().slice(-1)

		switch (lastDigit) {
			case '1':
				return st
			case '2':
				return nd
			case '3':
				return rd
			default:
				return th
		}
	}

	const pstDate = DateTime.fromISO(date)
		.setZone('America/Los_Angeles')
		.toFormat(format)

	const suffix = getNumberSuffix(pstDate)
	const returnDate = pstDate + suffix
	return returnDate
}

const pstDate = (date) => {
	const dateAsDate = new Date(date).toISOString().replace('Z', '')
	const pstDate = DateTime.fromISO(dateAsDate, {
		zone: 'America/Los_Angeles',
	})
		.toUTC()
		.toISO()
	return pstDate
}

export { displayExperienceDate, pstDate }
