import {
	ORG_LIST_REQUEST,
	ORG_LIST_SUCCESS,
	ORG_LIST_FAIL,
	ORG_DETAILS_REQUEST,
	ORG_DETAILS_SUCCESS,
	ORG_DETAILS_FAIL,
	ORG_DETAILS_RESET,
	// ORG_DELETE_REQUEST,
	// ORG_DELETE_SUCCESS,
	// ORG_DELETE_FAIL,
	// ORG_CREATE_REQUEST,
	// ORG_CREATE_SUCCESS,
	// ORG_CREATE_FAIL,
	// ORG_CREATE_RESET,
	// ORG_UPDATE_REQUEST,
	// ORG_UPDATE_SUCCESS,
	// ORG_UPDATE_FAIL,
	// ORG_UPDATE_RESET,
	// ORG_CREATE_ITINERARYITEM_REQUEST,
	// ORG_CREATE_ITINERARYITEM_SUCCESS,
	// ORG_CREATE_ITINERARYITEM_FAIL,
	// ORG_CREATE_ITINERARYITEM_RESET,
	// ORG_CREATE_TRIPOPTION_REQUEST,
	// ORG_CREATE_TRIPOPTION_SUCCESS,
	// ORG_CREATE_TRIPOPTION_FAIL,
	// ORG_CREATE_TRIPOPTION_RESET,
} from '../constants/orgConstants'

export const orgListReducer = (state = { orgs: [] }, action) => {
	switch (action.type) {
		case ORG_LIST_REQUEST:
			return { loading: true, orgs: [] }
		case ORG_LIST_SUCCESS:
			return { loading: false, orgs: action.payload }
		case ORG_LIST_FAIL:
			return { loading: false, error: action.payload }
		default:
			return state
	}
}

export const orgDetailsReducer = (state = { org: {} }, action) => {
	switch (action.type) {
		case ORG_DETAILS_REQUEST:
			return { loading: true, ...state }
		case ORG_DETAILS_SUCCESS:
			return { loading: false, org: action.payload }
		case ORG_DETAILS_FAIL:
			return { loading: false, error: action.payload }
		case ORG_DETAILS_RESET:
			return { org: {} }
		default:
			return state
	}
}

// export const experienceDeleteReducer = (state = {}, action) => {
// 	switch (action.type) {
// 		case EXPERIENCE_DELETE_REQUEST:
// 			return { loading: true }
// 		case EXPERIENCE_DELETE_SUCCESS:
// 			return { loading: false, success: true }
// 		case EXPERIENCE_DELETE_FAIL:
// 			return { loading: false, error: action.payload }
// 		default:
// 			return state
// 	}
// }

// export const experienceCreateReducer = (state = {}, action) => {
// 	switch (action.type) {
// 		case EXPERIENCE_CREATE_REQUEST:
// 			return { loading: true }
// 		case EXPERIENCE_CREATE_SUCCESS:
// 			return { loading: false, experience: action.payload, success: true }
// 		case EXPERIENCE_CREATE_FAIL:
// 			return { loading: false, error: action.payload }
// 		case EXPERIENCE_CREATE_RESET:
// 			return {}
// 		default:
// 			return state
// 	}
// }

// export const experienceUpdateReducer = (state = { experience: {} }, action) => {
// 	switch (action.type) {
// 		case EXPERIENCE_UPDATE_REQUEST:
// 			return { loading: true }
// 		case EXPERIENCE_UPDATE_SUCCESS:
// 			return { loading: false, experience: action.payload, success: true }
// 		case EXPERIENCE_UPDATE_FAIL:
// 			return { loading: false, error: action.payload }
// 		case EXPERIENCE_UPDATE_RESET:
// 			return { experience: {} }
// 		default:
// 			return state
// 	}
// }

// export const experienceItineraryItemCreateReducer = (state = {}, action) => {
// 	switch (action.type) {
// 		case EXPERIENCE_CREATE_ITINERARYITEM_REQUEST:
// 			return { loading: true }
// 		case EXPERIENCE_CREATE_ITINERARYITEM_SUCCESS:
// 			return { loading: false, success: true }
// 		case EXPERIENCE_CREATE_ITINERARYITEM_FAIL:
// 			return { loading: false, error: action.payload }
// 		case EXPERIENCE_CREATE_ITINERARYITEM_RESET:
// 			return {}
// 		default:
// 			return state
// 	}
// }

// export const experienceTripOptionCreateReducer = (state = {}, action) => {
// 	switch (action.type) {
// 		case EXPERIENCE_CREATE_TRIPOPTION_REQUEST:
// 			return { loading: true }
// 		case EXPERIENCE_CREATE_TRIPOPTION_SUCCESS:
// 			return { loading: false, success: true }
// 		case EXPERIENCE_CREATE_TRIPOPTION_FAIL:
// 			return { loading: false, error: action.payload }
// 		case EXPERIENCE_CREATE_TRIPOPTION_RESET:
// 			return {}
// 		default:
// 			return state
// 	}
// }
