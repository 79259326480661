import {
	LEAD_REGISTER_FAIL,
	LEAD_REGISTER_REQUEST,
	LEAD_REGISTER_SUCCESS,
	LEAD_LIST_EXPERIENCE_REQUEST,
	LEAD_LIST_EXPERIENCE_SUCCESS,
	LEAD_LIST_EXPERIENCE_FAIL,
	LEAD_LIST_EXPERIENCE_RESET,
} from '../constants/leadConstants'

export const leadRegisterReducer = (state = {}, action) => {
	switch (action.type) {
		case LEAD_REGISTER_REQUEST:
			return { loading: true }
		case LEAD_REGISTER_SUCCESS:
			return { success: true, loading: false, leadInfo: action.payload }
		case LEAD_REGISTER_FAIL:
			return { loading: false, error: action.payload }
		default:
			return state
	}
}

export const leadListExperienceReducer = (state = { leads: [] }, action) => {
	switch (action.type) {
		case LEAD_LIST_EXPERIENCE_REQUEST:
			return {
				loading: true,
			}
		case LEAD_LIST_EXPERIENCE_SUCCESS:
			return {
				loading: false,
				leads: action.payload,
			}
		case LEAD_LIST_EXPERIENCE_FAIL:
			return {
				loading: false,
				error: action.payload,
			}
		case LEAD_LIST_EXPERIENCE_RESET:
			return { leads: [] }

		default:
			return state
	}
}
