import axios from 'axios'

import {
	USERMEMBERSHIP_CREATE_REQUEST,
	USERMEMBERSHIP_CREATE_SUCCESS,
	USERMEMBERSHIP_CREATE_FAIL,
	USERMEMBERSHIP_DETAILS_REQUEST,
	USERMEMBERSHIP_DETAILS_SUCCESS,
	USERMEMBERSHIP_DETAILS_FAIL,
	USERMEMBERSHIP_PAY_REQUEST,
	USERMEMBERSHIP_PAY_FAIL,
	USERMEMBERSHIP_PAY_SUCCESS,
	USERMEMBERSHIP_LIST_MY_REQUEST,
	USERMEMBERSHIP_LIST_MY_SUCCESS,
	USERMEMBERSHIP_LIST_MY_FAIL,
	USERMEMBERSHIP_LIST_MY_RESET,
	USERMEMBERSHIP_LIST_ORG_FAIL,
	USERMEMBERSHIP_LIST_ORG_SUCCESS,
	USERMEMBERSHIP_LIST_ORG_REQUEST,
} from '../constants/userMembershipConstants'

export const createUserMembership =
	(userMembership) => async (dispatch, getState) => {
		try {
			dispatch({
				type: USERMEMBERSHIP_CREATE_REQUEST,
			})

			const {
				userLogin: { userInfo },
			} = getState()

			const config = {
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${userInfo.token}`,
				},
			}

			const { data } = await axios.post(
				`/api/userMemberships`,
				userMembership,
				config
			)

			dispatch({
				type: USERMEMBERSHIP_CREATE_SUCCESS,
				payload: data,
			})
		} catch (error) {
			dispatch({
				type: USERMEMBERSHIP_CREATE_FAIL,
				payload:
					error.response && error.response.data.message
						? error.response.data.message
						: error.message,
			})
		}
	}

export const getUserMembershipDetails = (id) => async (dispatch, getState) => {
	try {
		dispatch({
			type: USERMEMBERSHIP_DETAILS_REQUEST,
		})

		const {
			userLogin: { userInfo },
		} = getState()

		const config = {
			headers: {
				Authorization: `Bearer ${userInfo.token}`,
			},
		}

		const { data } = await axios.get(`/api/usermemberships/${id}`, config)

		dispatch({
			type: USERMEMBERSHIP_DETAILS_SUCCESS,
			payload: data,
		})
	} catch (error) {
		dispatch({
			type: USERMEMBERSHIP_DETAILS_FAIL,
			payload:
				error.response && error.response.data.message
					? error.response.data.message
					: error.message,
		})
	}
}

export const payMembership =
	(userMembershipId, paymentResult) => async (dispatch, getState) => {
		try {
			dispatch({
				type: USERMEMBERSHIP_PAY_REQUEST,
			})

			// const {
			// 	userLogin: { userInfo },
			// } = getState()

			// const config = {
			// 	headers: {
			// 		'Content-Type': 'application/json',
			// 		Authorization: `Bearer ${userInfo.token}`,
			// 	},
			// }

			const { data } = await axios.put(
				`/api/usermemberships/${userMembershipId}/pay`,
				paymentResult
				//config
			)

			dispatch({
				type: USERMEMBERSHIP_PAY_SUCCESS,
				payload: data,
			})
		} catch (error) {
			dispatch({
				type: USERMEMBERSHIP_PAY_FAIL,
				payload:
					error.response && error.response.data.message
						? error.response.data.message
						: error.message,
			})
		}
	}

export const listMyMemberships = () => async (dispatch, getState) => {
	try {
		dispatch({
			type: USERMEMBERSHIP_LIST_MY_REQUEST,
		})

		const {
			userLogin: { userInfo },
		} = getState()

		const config = {
			headers: {
				Authorization: `Bearer ${userInfo.token}`,
			},
		}

		const { data } = await axios.get(
			`/api/usermemberships/mymemberships`,
			config
		)

		dispatch({
			type: USERMEMBERSHIP_LIST_MY_SUCCESS,
			payload: data,
		})
	} catch (error) {
		dispatch({
			type: USERMEMBERSHIP_LIST_MY_FAIL,
			payload:
				error.response && error.response.data.message
					? error.response.data.message
					: error.message,
		})
	}
}

export const listOrgUserMemberships = (orgId) => async (dispatch, getState) => {
	try {
		dispatch({
			type: USERMEMBERSHIP_LIST_ORG_REQUEST,
		})

		const {
			userLogin: { userInfo },
		} = getState()

		const config = {
			headers: {
				Authorization: `Bearer ${userInfo.token}`,
			},
		}

		const { data } = await axios.get(
			`/api/usermemberships/orgmemberships/${orgId}`,
			config
		)

		dispatch({
			type: USERMEMBERSHIP_LIST_ORG_SUCCESS,
			payload: data,
		})
	} catch (error) {
		dispatch({
			type: USERMEMBERSHIP_LIST_ORG_FAIL,
			payload:
				error.response && error.response.data.message
					? error.response.data.message
					: error.message,
		})
	}
}
