import React, { Component } from 'react'
import { Container, Button } from 'react-bootstrap'

class ErrorBoundaryScreen extends Component {
	constructor(props) {
		super(props)
		this.state = { hasError: false }
	}

	static getDerivedStateFromError(error) {
		return { hasError: true }
	}

	componentDidCatch(error, errorInfo) {
		console.error('Error Boundary caught an error:', error, errorInfo)
		// Optionally log error to an external service
	}

	render() {
		if (this.state.hasError) {
			return (
				<Container className='text-center my-4'>
					<h1>Something went wrong.</h1>
					<p>We're sorry, something went wrong while rendering this page.</p>
					<Button variant='primary' onClick={() => window.location.reload()}>
						Refresh Page
					</Button>
				</Container>
			)
		}

		return this.props.children
	}
}

export default ErrorBoundaryScreen
