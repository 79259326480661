import React, { useEffect, useState } from 'react'
import { LinkContainer } from 'react-router-bootstrap'
import { Table, Button, Row, Col, Container } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../../components/Message'
import Loader from '../../components/Loader'
import {
	getExperienceDetails,
	updateExperience,
} from '../../services/actions/experienceActions'

import DetailHeader from '../../components/DetailHeader'

const ExperienceListTripOptionScreen = ({ history, match }) => {
	const experienceId = match.params.id

	const dispatch = useDispatch()

	const [tripOption, setTripOption] = useState([])

	const experienceDetails = useSelector((state) => state.experienceDetails)
	const { loading, error, experience } = experienceDetails

	const experienceUpdate = useSelector((state) => state.experienceUpdate)
	const {
		loading: loadingUpdate,
		error: errorUpdate,
		success: successUpdate,
	} = experienceUpdate

	const userLogin = useSelector((state) => state.userLogin)
	const { userInfo } = userLogin

	useEffect(() => {
		if (!experience.name || experience._id !== experienceId) {
			dispatch(getExperienceDetails(experienceId))
		} else {
			setTripOption(experience.tripOption)
		}

		if (!userInfo.isAdmin) {
			history.push('/login')
		}
	}, [
		dispatch,
		history,
		userInfo,
		successUpdate,
		experience,
		experienceId,
		tripOption,
	])

	const deleteHandler = (id) => {
		if (window.confirm('Are you sure you would like to do this?')) {
			const newTripOption = tripOption.filter((item) => {
				return item._id !== id
			})

			setTripOption(newTripOption)

			dispatch(
				updateExperience({
					_id: experienceId,
					tripOption: newTripOption,
				})
			)
			dispatch(getExperienceDetails(experienceId))
		}
	}

	const createTripOptionItemHandler = () => {
		tripOption.push({
			dayName: 'Day',
			sortOrder: 0,
			title: 'Title',
			description: 'Description',
			image: 'Image',
		})

		dispatch(
			updateExperience({
				_id: experienceId,
				tripOption: tripOption,
			})
		)
		dispatch(getExperienceDetails(experienceId))
	}

	return (
		<>
			<DetailHeader />
			<Container>
				<Row className='align-items-center'>
					<Col>
						<h1>Trip Options</h1>
					</Col>
					<Col className='text-right'>
						<Button className='my-3' onClick={createTripOptionItemHandler}>
							<i className='fas fa-plus'></i> Create Trip Option
						</Button>
					</Col>
				</Row>
				{errorUpdate && <Message variant='danger'>{errorUpdate}</Message>}
				{loading || loadingUpdate ? (
					<Loader />
				) : error ? (
					<Message variant='danger'>{error}</Message>
				) : tripOption.length > 0 ? (
					<Table striped bordered hover responsive className='table-sm'>
						<thead>
							<tr>
								<th>TITLE</th>
								<th>DESC</th>
								<th>PRICE</th>
								<th></th>
							</tr>
						</thead>
						<tbody>
							{tripOption.map((item) => (
								<tr key={item._id}>
									<td>{item.title}</td>
									<td>{item.description}</td>
									<td>{item.fullPrice}</td>

									<td>
										<LinkContainer
											to={`/admin/experience/${experience._id}/TripOption/${item._id}`}
										>
											<Button variant='light' className='btn-sm'>
												<i className='fas fa-edit'></i>
											</Button>
										</LinkContainer>
										<Button
											variant='danger'
											className='btn-sm'
											onClick={() => deleteHandler(item._id)}
										>
											<i className='fas fa-trash'></i>
										</Button>
									</td>
								</tr>
							))}
						</tbody>
					</Table>
				) : (
					<h2>No trip options have been created</h2>
				)}
			</Container>
		</>
	)
}

export default ExperienceListTripOptionScreen
