import React, { useState } from 'react'
import { Form, Button, Row, Col, Container } from 'react-bootstrap'
import Message from '../../components/Message'
import Loader from '../../components/Loader'
import Meta from '../../components/Meta'
import axios from 'axios'

const ForgotPasswordScreen = ({ match }) => {
	const [email, setEmail] = useState('')
	const [error, setError] = useState('')
	const [success, setSuccess] = useState('')
	const [loading, setLoading] = useState(false)

	const forgotPasswordHandler = async (e) => {
		e.preventDefault()
		setError('')
		setLoading(true)

		const config = {
			header: {
				'Content-Type': 'application/json',
			},
		}

		try {
			const { data } = await axios.post(
				'/api/users/forgotpassword',
				{ email },
				config
			)

			setSuccess(data.data)
			setLoading(false)
		} catch (error) {
			setError(error.response.data.message)
			setLoading(false)
			setEmail('')
			setTimeout(() => {
				setError('')
			}, 5000)
		}
	}
	return (
		<Container>
			<Meta title={`I Do Tours | Forgot Password`} />
			<Row className='my-4'>
				<Col md={5}>
					<h2>Find Your Account</h2>
					{error && <Message variant='danger'>{error}</Message>}
					{success && <Message variant='success'>{success}</Message>}
					{loading && <Loader />}
					<Form onSubmit={forgotPasswordHandler}>
						<Form.Group controlId='email'>
							<Form.Label>Email</Form.Label>
							<Form.Control
								type='email'
								placeholder='Enter your email'
								value={email}
								onChange={(e) => setEmail(e.target.value)}
								required
								disabled={success}
							></Form.Control>
						</Form.Group>

						<Button type='submit' variant='primary' disabled={success}>
							Send Reset Email
						</Button>
					</Form>
				</Col>
			</Row>
		</Container>
	)
}

export default ForgotPasswordScreen
