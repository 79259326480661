import {
	MEMBERSHIP_FLOW_SET_MEMBERSHIP_PROGRAM,
	MEMBERSHIP_FLOW_SET_MEMBERSHIP_CLUB,
} from '../constants/membershipFlowConstants'

export const membershipFlowReducer = (
	state = { flowMembershipProgram: {} },
	action
) => {
	switch (action.type) {
		case MEMBERSHIP_FLOW_SET_MEMBERSHIP_PROGRAM:
			return {
				...state,
				flowMembershipProgram: action.payload,
			}
		case MEMBERSHIP_FLOW_SET_MEMBERSHIP_CLUB:
			return {
				...state,
				flowMembershipOrg: action.payload,
			}

		default:
			return state
	}
}
