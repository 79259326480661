import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { LinkContainer } from 'react-router-bootstrap'
import { Table, Button } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import Loader from '../components/Loader'
import { listOrders } from '../services/actions/orderActions'

const OrderListScreen = ({ history }) => {
	const dispatch = useDispatch()

	const orderList = useSelector((state) => state.orderList)
	const { error, orders } = orderList

	const userLogin = useSelector((state) => state.userLogin)
	const { userInfo } = userLogin

	useEffect(() => {
		if (userInfo && userInfo.isAdmin) {
			dispatch(listOrders())
		} else {
			history.push('/login')
		}
	}, [dispatch, history, userInfo])

	return (
		<div className='my-4'>
			<h1>Orders</h1>
			{!orders ? (
				<Loader />
			) : error ? (
				<Message variant='danger'>{error}</Message>
			) : orders.length > 0 ? (
				<>Under construction...</>
			) : (
				// <Table striped bordered hover responsive className='table-sm'>
				// 	<thead>
				// 		<tr>
				// 			<th>Experience</th>
				// 			<th>USER</th>
				// 			<th>DATE</th>
				// 			<th>TOTAL PRICE</th>
				// 			<th>TOTAL PAID</th>
				// 			<th>TOTAL REMAINING</th>
				// 			<th></th>
				// 		</tr>
				// 	</thead>
				// 	<tbody>
				// 		{orders.map((order) => (
				// 			<tr key={order._id}>
				// 				<td>
				// 					<Link to={`/admin/experience/${order.experience._id}/orders`}>
				// 						{order.experience.name}
				// 					</Link>
				// 				</td>
				// 				<td>
				// 					<Link to={`/admin/user/${order.user._id}/orders`}>
				// 						{order.user && order.user.name}
				// 					</Link>
				// 				</td>
				// 				<td>{order.createdAt.substring(0, 10)}</td>
				// 				<td>${order.totalPrice}</td>
				// 				<td>${order.totalAmountPaid}</td>
				// 				<td>${order.totalAmountRemaining}</td>

				// 				<td>
				// 					<LinkContainer to={`/order/${order._id}`}>
				// 						<Button variant='light' className='btn-sm'>
				// 							Details
				// 						</Button>
				// 					</LinkContainer>
				// 				</td>
				// 			</tr>
				// 		))}
				// 	</tbody>
				// </Table>
				<h2>No orders have been placed</h2>
			)}
		</div>
	)
}

export default OrderListScreen
