import React from 'react'
import { useSelector } from 'react-redux'
import { LinkContainer } from 'react-router-bootstrap'
import { Navbar, Nav, Container } from 'react-bootstrap'

const DetailHeader = () => {
	const userLogin = useSelector((state) => state.userLogin)
	const { userInfo } = userLogin

	const experienceDetails = useSelector((state) => state.experienceDetails)
	const { experience } = experienceDetails

	const experienceId = experience._id

	return (
		<>
			{userInfo && userInfo.isAdmin && (
				<Navbar
					style={{ backgroundColor: '#cccccc' }}
					variant='dark'
					expand='sm'
					collapseOnSelect
					className='navbar'
				>
					<Container>
						<LinkContainer
							to={`/admin/experience/${experienceId}/details`}
							style={{ color: '#000', fontSize: '1rem' }}
						>
							<Navbar.Brand>{experience.name}</Navbar.Brand>
						</LinkContainer>
						<Navbar.Toggle aria-controls='basic-navbar-nav' />
						<Navbar.Collapse id='basic-navbar-nav'>
							<Nav className='ml-auto'>
								<LinkContainer to={`/admin/experience/${experienceId}/details`}>
									<Nav.Item>Details</Nav.Item>
								</LinkContainer>
								<LinkContainer
									to={`/admin/experience/${experienceId}/itinerary`}
								>
									<Nav.Item>Itinerary</Nav.Item>
								</LinkContainer>
								<LinkContainer
									to={`/admin/experience/${experienceId}/accommodations`}
								>
									<Nav.Item>Accommodation</Nav.Item>
								</LinkContainer>
								<LinkContainer
									to={`/admin/experience/${experienceId}/destination`}
								>
									<Nav.Item>Destination</Nav.Item>
								</LinkContainer>
								<LinkContainer
									to={`/admin/experience/${experienceId}/tripoption`}
								>
									<Nav.Item>Trip Options</Nav.Item>
								</LinkContainer>
								<LinkContainer to={`/admin/experience/${experienceId}/orders`}>
									<Nav.Item>Orders</Nav.Item>
								</LinkContainer>
								<LinkContainer to={`/admin/experience/${experienceId}/leads`}>
									<Nav.Item>Leads</Nav.Item>
								</LinkContainer>
							</Nav>
						</Navbar.Collapse>
					</Container>
				</Navbar>
			)}
		</>
	)
}

export default DetailHeader
