import React from 'react'
import { useSelector } from 'react-redux'
import Experience from '../Experience'
import { Row, Col, ListGroup, Container } from 'react-bootstrap'
import Message from '../Message'
import Loader from '../Loader'
import './MyExperiencesGallery.css'

const MyExperiencesGallery = ({ title }) => {
	const orderListMy = useSelector((state) => state.orderListMy)
	const userDetails = useSelector((state) => state.userDetails)
	const { orders, loading = true } = orderListMy
	const { user } = userDetails

	return (
		<div className='my-experiences-gallery'>
			<Container>
				<Row>
					<Col md={12} className='gallery-container'>
						<ListGroup variant='flush'>
							{loading ? (
								<Loader />
							) : orders ? (
								<ListGroup.Item>
									<h2>{title}</h2>
									<div className='cards__wrapper'>
										<ul className='cards__items'>
											{orders?.map((order) => (
												<Col key={order._id} sm={12} md={6} lg={4} xl={3}>
													<Experience order={order} user={user} />
												</Col>
											))}
										</ul>
									</div>
								</ListGroup.Item>
							) : (
								''
							)}
						</ListGroup>
					</Col>
				</Row>
			</Container>
		</div>
	)
}

MyExperiencesGallery.defaultProps = {
	title: 'My Experiences',
}

export default MyExperiencesGallery
