import React, { useState, useEffect } from 'react'
import { Row, Col, ListGroup, Image, Card, Container } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../../../../components/Message'
import Loader from '../../../../components/Loader'
import Meta from '../../../../components/Meta'
import { getOrgDetails } from '../../../../services/actions/orgActions'
import { getUserMembershipDetails } from '../../../../services/actions/userMembershipActions'
import { getUserDetails } from '../../../../services/actions/userActions'
import MembershipPaymentButton from '../../../../components/MembershipPaymentButton/MembershipPaymentButton'
import './MembershipPaymentScreen.css'

const MembershipPaymentScreen = ({ match, history }) => {
	const dispatch = useDispatch()
	const orgSlug = match.params.slug
	const membershipProgramId = match.params.membershipprogramid
	const userMembershipId = match.params.usermembershipid

	// Logged in user details from state
	const userLogin = useSelector((state) => state.userLogin)
	const { userInfo } = userLogin

	// User details of current user profile from state
	const userDetails = useSelector((state) => state.userDetails)
	const { user } = userDetails

	// Org details from state
	const orgDetails = useSelector((state) => state.orgDetails)
	const { loading: orgLoading, error: orgError, org } = orgDetails

	// Get all details of membership program being purchased
	const selectedMembershipProgram = org?.membershipPrograms?.find(
		(program) => program._id === membershipProgramId
	)

	// UserMembership details from state
	const userMembershipDetails = useSelector(
		(state) => state.userMembershipDetails
	)
	const {
		userMembership,
		loading: userMembershipLoading,
		error: userMembershipError,
	} = userMembershipDetails

	// OwedToday calculations
	const [owedToday, setOwedToday] = useState(null)

	useEffect(() => {
		if (!userMembershipLoading && userMembership) {
			const calculateOwedToday =
				userMembership.status === 'Paid in full'
					? 0
					: userMembership.status === 'Open'
					? userMembership.totalAmountRemaining
					: 0
			setOwedToday(parseFloat(calculateOwedToday).toFixed(2))
		}
	}, [userMembership, userMembershipLoading])

	useEffect(() => {
		if (!Object.keys(org).length) {
			dispatch(getOrgDetails(orgSlug))
		}
	}, [org, orgSlug, dispatch])

	useEffect(() => {
		if (!userInfo) {
			history.push('/login')
		} else if (!user?._id) {
			dispatch(getUserDetails('profile'))
		}
	}, [history, user, userInfo])

	useEffect(() => {
		if (userMembership?._id !== userMembershipId) {
			dispatch(getUserMembershipDetails(userMembershipId))
		}
	}, [userMembership, userMembershipId, dispatch])

	const membershipProduct = {
		description: selectedMembershipProgram?.name,
		price: owedToday,
	}

	const enableButton = owedToday > 0

	return orgLoading || userMembershipLoading || owedToday === null ? (
		<Loader />
	) : orgError ? (
		<Message variant='danger'>{orgError}</Message>
	) : userMembershipError ? (
		<Message variant='danger'>{userMembershipError}</Message>
	) : (
		<Container>
			<Meta title={`I Do Tours | Membership`} />
			<div className='my-4'>
				<h1>Membership</h1>
				<Row>
					<Col md={8}>
						{userMembership.status !== 'Open' ? (
							<Message variant='success'>{userMembership.status}</Message>
						) : (
							<Message variant='primary'>Not paid</Message>
						)}
						<ListGroup variant='flush'>
							<ListGroup.Item>
								<h2>Member Info</h2>
								<p>
									<strong>Name: </strong> {userMembership?.user?.name}
								</p>
								<p>
									<strong>Email: </strong> {userMembership?.user?.email}
								</p>
							</ListGroup.Item>
							<ListGroup.Item>
								<h2>Membership</h2>
								{userMembership.length === 0 ? (
									<Message>
										You are not purchasing a membership right now
									</Message>
								) : (
									<ListGroup variant='flush'>
										<ListGroup.Item>
											<Row>
												<Col md={1} className='p-0'>
													<Image
														src={selectedMembershipProgram?.image}
														alt={selectedMembershipProgram?.name}
														fluid
														rounded
													/>
												</Col>
												<Col>{selectedMembershipProgram?.name}</Col>
												<Col md={4}></Col>
											</Row>
										</ListGroup.Item>
									</ListGroup>
								)}
							</ListGroup.Item>
						</ListGroup>
					</Col>
					<Col md={4}>
						<Card>
							<ListGroup>
								<ListGroup.Item>
									<h2>Membership Summary</h2>
								</ListGroup.Item>
								<ListGroup.Item>
									<Row>
										<Col>Membership Price</Col>
										<Col>
											$
											{userMembership.basePrice &&
												userMembership.basePrice.toFixed(2)}
										</Col>
									</Row>
								</ListGroup.Item>
								{userMembership.taxPrice > 0 ? (
									<ListGroup.Item>
										<Row>
											<Col>Taxes and Fees</Col>
											<Col>${userMembership?.taxPrice?.toFixed(2)}</Col>
										</Row>
									</ListGroup.Item>
								) : (
									''
								)}
								<ListGroup.Item>
									<Row>
										<Col>Total</Col>
										<Col>${userMembership?.totalPrice?.toFixed(2)}</Col>
									</Row>
								</ListGroup.Item>
								<ListGroup.Item>
									<Row>
										<Col>
											{userMembership.totalAmountPaid > 0
												? 'Remaining Balance'
												: 'Owed Today'}
										</Col>
										<Col>${owedToday}</Col>
									</Row>
								</ListGroup.Item>
								{userMembership.status !== 'Paid in full' && (
									<ListGroup.Item>
										{enableButton ? (
											<MembershipPaymentButton
												product={membershipProduct}
												userMembershipId={userMembershipId}
											/>
										) : (
											'Not available for purchase'
										)}
									</ListGroup.Item>
								)}
							</ListGroup>
						</Card>
					</Col>
				</Row>
			</div>
		</Container>
	)
}

export default MembershipPaymentScreen
