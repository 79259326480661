import {
	EXPERIENCE_LIST_REQUEST,
	EXPERIENCE_LIST_SUCCESS,
	EXPERIENCE_LIST_FAIL,
	EXPERIENCE_DETAILS_REQUEST,
	EXPERIENCE_DETAILS_SUCCESS,
	EXPERIENCE_DETAILS_FAIL,
	EXPERIENCE_DETAILS_RESET,
	EXPERIENCE_DELETE_REQUEST,
	EXPERIENCE_DELETE_SUCCESS,
	EXPERIENCE_DELETE_FAIL,
	EXPERIENCE_CREATE_REQUEST,
	EXPERIENCE_CREATE_SUCCESS,
	EXPERIENCE_CREATE_FAIL,
	EXPERIENCE_CREATE_RESET,
	EXPERIENCE_UPDATE_REQUEST,
	EXPERIENCE_UPDATE_SUCCESS,
	EXPERIENCE_UPDATE_FAIL,
	EXPERIENCE_UPDATE_RESET,
	EXPERIENCE_CREATE_ITINERARYITEM_REQUEST,
	EXPERIENCE_CREATE_ITINERARYITEM_SUCCESS,
	EXPERIENCE_CREATE_ITINERARYITEM_FAIL,
	EXPERIENCE_CREATE_ITINERARYITEM_RESET,
	EXPERIENCE_CREATE_TRIPOPTION_REQUEST,
	EXPERIENCE_CREATE_TRIPOPTION_SUCCESS,
	EXPERIENCE_CREATE_TRIPOPTION_FAIL,
	EXPERIENCE_CREATE_TRIPOPTION_RESET,
} from '../constants/experienceConstants'

export const experienceListReducer = (state = { experiences: [] }, action) => {
	switch (action.type) {
		case EXPERIENCE_LIST_REQUEST:
			return { loading: true, experiences: [] }
		case EXPERIENCE_LIST_SUCCESS:
			return { loading: false, experiences: action.payload }
		case EXPERIENCE_LIST_FAIL:
			return { loading: false, error: action.payload }
		default:
			return state
	}
}

export const experienceDetailsReducer = (
	state = { experience: {} },
	action
) => {
	switch (action.type) {
		case EXPERIENCE_DETAILS_REQUEST:
			return { loading: true, ...state }
		case EXPERIENCE_DETAILS_SUCCESS:
			return { loading: false, experience: action.payload }
		case EXPERIENCE_DETAILS_FAIL:
			return { loading: false, error: action.payload }
		case EXPERIENCE_DETAILS_RESET:
			return { experience: {} }
		default:
			return state
	}
}

export const experienceDeleteReducer = (state = {}, action) => {
	switch (action.type) {
		case EXPERIENCE_DELETE_REQUEST:
			return { loading: true }
		case EXPERIENCE_DELETE_SUCCESS:
			return { loading: false, success: true }
		case EXPERIENCE_DELETE_FAIL:
			return { loading: false, error: action.payload }
		default:
			return state
	}
}

export const experienceCreateReducer = (state = {}, action) => {
	switch (action.type) {
		case EXPERIENCE_CREATE_REQUEST:
			return { loading: true }
		case EXPERIENCE_CREATE_SUCCESS:
			return { loading: false, experience: action.payload, success: true }
		case EXPERIENCE_CREATE_FAIL:
			return { loading: false, error: action.payload }
		case EXPERIENCE_CREATE_RESET:
			return {}
		default:
			return state
	}
}

export const experienceUpdateReducer = (state = { experience: {} }, action) => {
	switch (action.type) {
		case EXPERIENCE_UPDATE_REQUEST:
			return { loading: true }
		case EXPERIENCE_UPDATE_SUCCESS:
			return { loading: false, experience: action.payload, success: true }
		case EXPERIENCE_UPDATE_FAIL:
			return { loading: false, error: action.payload }
		case EXPERIENCE_UPDATE_RESET:
			return { experience: {} }
		default:
			return state
	}
}

export const experienceItineraryItemCreateReducer = (state = {}, action) => {
	switch (action.type) {
		case EXPERIENCE_CREATE_ITINERARYITEM_REQUEST:
			return { loading: true }
		case EXPERIENCE_CREATE_ITINERARYITEM_SUCCESS:
			return { loading: false, success: true }
		case EXPERIENCE_CREATE_ITINERARYITEM_FAIL:
			return { loading: false, error: action.payload }
		case EXPERIENCE_CREATE_ITINERARYITEM_RESET:
			return {}
		default:
			return state
	}
}

export const experienceTripOptionCreateReducer = (state = {}, action) => {
	switch (action.type) {
		case EXPERIENCE_CREATE_TRIPOPTION_REQUEST:
			return { loading: true }
		case EXPERIENCE_CREATE_TRIPOPTION_SUCCESS:
			return { loading: false, success: true }
		case EXPERIENCE_CREATE_TRIPOPTION_FAIL:
			return { loading: false, error: action.payload }
		case EXPERIENCE_CREATE_TRIPOPTION_RESET:
			return {}
		default:
			return state
	}
}
