import React, { useState, useEffect } from 'react'
import { LinkContainer } from 'react-router-bootstrap'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Container, Table, Button, Card, Col, Row } from 'react-bootstrap'
import { CSVLink } from 'react-csv'
import DetailHeader from '../components/DetailHeader'
import Loader from '../components/Loader'
import { getUserDetails } from '../services/actions/userActions'
import { getExperienceDetails } from '../services/actions/experienceActions'
import {
	listExperienceOrders,
	deleteOrder,
} from '../services/actions/orderActions'
import axios from 'axios'

const ExperienceListOrdersScreen = ({ match, history }) => {
	const dispatch = useDispatch()

	const [email, setEmail] = useState('')
	const [emailError, setEmailError] = useState('')
	const [emailSuccess, setEmailSuccess] = useState('')
	const [emailLoading, setEmailLoading] = useState(false)

	const experienceId = match.params.id

	const experienceDetails = useSelector((state) => state.experienceDetails)
	const { experience } = experienceDetails

	const userLogin = useSelector((state) => state.userLogin)
	const { userInfo } = userLogin

	const orderListExperience = useSelector((state) => state.orderListExperience)
	const { orders } = orderListExperience

	const orderDelete = useSelector((state) => state.orderDelete)
	const {
		loading: loadingDelete,
		success: successDelete,
		error: errorDelete,
	} = orderDelete

	useEffect(() => {
		if (!experience?._id) {
			dispatch(getExperienceDetails(experienceId))
		}
		if (experience?._id) {
			dispatch(listExperienceOrders(experienceId))
		}
	}, [experienceId, experience, successDelete, dispatch])

	useEffect(() => {
		if (!userInfo) {
			history.push('/')
		} else if (!userInfo.isAdmin) {
			history.push('/')
		} else if (!userInfo || !userInfo.name || !orders) {
			dispatch(getUserDetails('profile'))
		}
	}, [dispatch, history, userInfo, orders])

	//Dashboard Figures
	const numCompletedOrders = orders?.filter((order) => {
		return order.orderStatus === 'Paid in full'
	})
	const collectedCompletedOrders = sum(
		orders?.filter((order) => {
			return order.orderStatus !== 'open'
		}),
		'totalAmountPaid'
	)

	const amountRemainingOrders = sum(orders, 'totalAmountRemaining')
	//End Dashboard Figures

	//Email Handler
	const emailHandler = async (orderId) => {
		if (
			window.confirm(
				'Are you sure you would like to send an order confirmation email to this user?'
			)
		) {
			setEmailError('')
			setEmailLoading(true)

			const config = {
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${userInfo.token}`,
				},
			}

			try {
				const { data } = await axios.post(
					`/api/order/${orderId}/email`,
					{},
					config
				)

				setEmailSuccess(data.data)
				setEmailLoading(false)
				window.alert('Email sent!')
			} catch (error) {
				window.alert(error.response.data.message)
				setEmailError(error.response.data.message)
				setEmailLoading(false)
				setEmail('')
				setTimeout(() => {
					setEmailError('Email action has timed out')
				}, 5000)
			}
		}
	}
	//End Email Handler

	//Delete order handler
	const deleteHandler = (id) => {
		if (window.confirm('Are you sure you would like to do this?')) {
			dispatch(deleteOrder(id))
		}
	}
	//End delete order handler

	function sum(arr, key) {
		let res
		if (arr?.length) {
			res = arr.reduce((a, b) => a + (b[key] || 0), 0)
		}
		return res
	}

	let orderExport = orders?.map(
		({
			addOnsPrice,
			createdAt,
			experience: { name: experience },
			orderStatus,
			paymentStructure,
			taxPrice,
			totalAmountPaid,
			totalAmountRemaining,
			totalPrice,
			updatedAt,
			user: { name: traveler },
			user: { email },
			user: { phone },
			user: { dateOfBirth: DOB },
		}) => ({
			experience,
			traveler,
			email,
			phone,
			DOB,
			orderStatus,
			createdAt,
			experience,
			paymentStructure,
			addOnsPrice,
			taxPrice,
			totalPrice,
			totalAmountPaid,
			totalAmountRemaining,
			updatedAt,
		})
	)

	return (
		<>
			<DetailHeader />
			<Container className='my-4'>
				<Row className='align-items-center'>
					<Col>
						<h1>Orders</h1>
					</Col>
					{orderExport && (
						<Col className='text-right'>
							<CSVLink
								data={orderExport}
								filename={`Orders - ${experience?.name}.csv`}
							>
								<Button className='my-3'>Export Orders</Button>
							</CSVLink>
						</Col>
					)}
				</Row>

				{!orders ? (
					<Loader />
				) : orders.length > 0 ? (
					<>
						<Row>
							<Col lg='3' md='12'>
								<Card className='my-2' bg='light'>
									<Card.Header>Total Orders</Card.Header>
									<Card.Body>
										<Card.Title>{orders?.length}</Card.Title>
									</Card.Body>
								</Card>
							</Col>
							<Col lg='3' md='12'>
								<Card className='my-2' bg='light'>
									<Card.Header>Completed Orders</Card.Header>
									<Card.Body>
										<Card.Title>{numCompletedOrders.length}</Card.Title>
									</Card.Body>
								</Card>
							</Col>
							<Col lg='3' md='12'>
								<Card className='my-2' bg='light'>
									<Card.Header>Total Collected</Card.Header>
									<Card.Body>
										<Card.Title>
											$
											{collectedCompletedOrders
												? collectedCompletedOrders.toFixed(2)
												: Number(0).toFixed(2)}
										</Card.Title>
									</Card.Body>
								</Card>
							</Col>
							<Col lg='3' md='12'>
								<Card className='my-2' bg='light'>
									<Card.Header>Total Remaining</Card.Header>
									<Card.Body>
										<Card.Title>
											$
											{amountRemainingOrders
												? amountRemainingOrders.toFixed(2)
												: 0}
										</Card.Title>
									</Card.Body>
								</Card>
							</Col>
						</Row>

						<Table striped bordered hover responsive className='table-sm'>
							<thead>
								<tr>
									<th>USER</th>
									<th>EMAIL</th>
									<th>STATUS</th>
									<th>TOTAL PRICE</th>
									<th>TOTAL PAID</th>
									<th>REMAINING</th>
									<th></th>
								</tr>
							</thead>
							<tbody>
								{orders.map((order) => (
									<tr key={order._id}>
										<td>
											<Link to={`/admin/user/${order.user._id}/orders`}>
												{order.user && order.user.name}
											</Link>
										</td>
										<td>{order.user.email}</td>
										<td>{order.orderStatus}</td>
										<td>${order.totalPrice}</td>
										<td>${order.totalAmountPaid}</td>
										<td>${order.totalAmountRemaining}</td>

										<td>
											<Button
												onClick={() => emailHandler(order._id)}
												variant='light'
												className='btn-sm m-1'
											>
												Resend Email
											</Button>
											<LinkContainer to={`/order/${order._id}`}>
												<Button variant='light' className='btn-sm m-1'>
													<i className='fas fa-info-circle fa-lg'></i>
												</Button>
											</LinkContainer>
											<Button
												variant='danger'
												className='btn-sm'
												onClick={() => deleteHandler(order._id)}
											>
												<i className='fas fa-trash fa-lg'></i>
											</Button>
										</td>
									</tr>
								))}
							</tbody>
						</Table>
					</>
				) : (
					<h2>No orders have been placed</h2>
				)}
			</Container>
		</>
	)
}

export default ExperienceListOrdersScreen
