import React, { useEffect, useState } from 'react'
import { LinkContainer } from 'react-router-bootstrap'
import { Table, Button, Row, Col, Container } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import Loader from '../components/Loader'
import DetailHeader from '../components/DetailHeader'
import {
	getExperienceDetails,
	updateExperience,
} from '../services/actions/experienceActions'

const ExperienceListItineraryScreen = ({ history, match }) => {
	const experienceId = match.params.id

	const dispatch = useDispatch()

	const [itinerary, setItinerary] = useState([])

	const experienceDetails = useSelector((state) => state.experienceDetails)
	const { loading, error, experience } = experienceDetails

	const experienceUpdate = useSelector((state) => state.experienceUpdate)
	const {
		loading: loadingUpdate,
		error: errorUpdate,
		success: successUpdate,
	} = experienceUpdate

	const userLogin = useSelector((state) => state.userLogin)
	const { userInfo } = userLogin

	useEffect(() => {
		if (!experience.name || experience._id !== experienceId) {
			dispatch(getExperienceDetails(experienceId))
		} else {
			setItinerary(experience.itinerary)
		}

		if (!userInfo.isAdmin) {
			history.push('/login')
		}
	}, [
		dispatch,
		history,
		userInfo,
		successUpdate,
		experience,
		experienceId,
		itinerary,
	])

	const deleteHandler = (id) => {
		if (window.confirm('Are you sure you would like to do this?')) {
			const newItinerary = itinerary.filter((item) => {
				return item._id !== id
			})

			setItinerary(newItinerary)

			dispatch(
				updateExperience({
					_id: experienceId,
					itinerary: newItinerary,
				})
			)
			dispatch(getExperienceDetails(experienceId))
		}
	}

	const createItineraryItemHandler = () => {
		itinerary.push({
			dayName: 'Day',
			sortOrder: 0,
			title: 'Title',
			description: 'Description',
			image: 'Image',
		})

		dispatch(
			updateExperience({
				_id: experienceId,
				itinerary: itinerary,
			})
		)
		dispatch(getExperienceDetails(experienceId))
	}

	return (
		<>
			<DetailHeader />
			<Container>
				<Row className='align-items-center'>
					<Col>
						<h1>Itinerary</h1>
					</Col>
					<Col className='text-right'>
						<Button className='my-3' onClick={createItineraryItemHandler}>
							<i className='fas fa-plus'></i> Create Itinerary Item
						</Button>
					</Col>
				</Row>
				{errorUpdate && <Message variant='danger'>{errorUpdate}</Message>}
				{loading || loadingUpdate ? (
					<Loader />
				) : error ? (
					<Message variant='danger'>{error}</Message>
				) : (
					<Table striped bordered hover responsive className='table-sm'>
						<thead>
							<tr>
								<th>DAY</th>
								<th>SORT ORDER</th>
								<th>TITLE</th>
								<th>DESC</th>
								<th>IMAGE</th>
								<th></th>
							</tr>
						</thead>
						<tbody>
							{itinerary.map((item) => (
								<tr key={item._id}>
									<td>{item.dayName}</td>
									<td>{item.sortOrder}</td>
									<td>{item.title}</td>
									<td>{item.description}</td>
									<td>{item.image}</td>

									<td>
										<LinkContainer
											to={`/admin/experience/${experience._id}/itinerary/${item._id}`}
										>
											<Button variant='light' className='btn-sm'>
												<i className='fas fa-edit'></i>
											</Button>
										</LinkContainer>
										<Button
											variant='danger'
											className='btn-sm'
											onClick={() => deleteHandler(item._id)}
										>
											<i className='fas fa-trash'></i>
										</Button>
									</td>
								</tr>
							))}
						</tbody>
					</Table>
				)}
			</Container>
		</>
	)
}

export default ExperienceListItineraryScreen
