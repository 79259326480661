import React from 'react'
import { useSelector } from 'react-redux'
import { LinkContainer } from 'react-router-bootstrap'
import { Navbar, Nav, Container } from 'react-bootstrap'

const UserDetailHeader = () => {
	const userLogin = useSelector((state) => state.userLogin)
	const { userInfo } = userLogin
	const { user } = useSelector((state) => state.userDetails)

	return (
		<>
			{userInfo && userInfo.isAdmin && (
				<Navbar
					style={{ backgroundColor: '#cccccc' }}
					variant='dark'
					expand='sm'
					collapseOnSelect
					className='navbar'
				>
					<Container>
						<LinkContainer
							to={`/admin/user/${user._id}/edit`}
							style={{ color: '#000', fontSize: '1rem' }}
						>
							<Navbar.Brand>{user.name}</Navbar.Brand>
						</LinkContainer>
						<Navbar.Toggle aria-controls='basic-navbar-nav' />
						<Navbar.Collapse id='basic-navbar-nav'>
							<Nav className='ml-auto'>
								<LinkContainer to={`/admin/user/${user._id}/edit`}>
									<Nav.Item>Details</Nav.Item>
								</LinkContainer>
								<LinkContainer to={`/admin/user/${user._id}/orders`}>
									<Nav.Item>Orders</Nav.Item>
								</LinkContainer>
							</Nav>
						</Navbar.Collapse>
					</Container>
				</Navbar>
			)}
		</>
	)
}

export default UserDetailHeader
