import React, { useEffect, useState } from 'react'
import { LinkContainer } from 'react-router-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { Container, Table, Button, Card, Col, Row } from 'react-bootstrap'
import { CSVLink } from 'react-csv'
import Loader from '../../../components/Loader'
import { getUserDetails } from '../../../services/actions/userActions'
import { getOrgDetails } from '../../../services/actions/orgActions'
import { listOrgUserMemberships } from '../../../services/actions/userMembershipActions'

const OrgManageScreen = ({ match, history }) => {
	const dispatch = useDispatch()
	const orgSlug = match.params.slug
	const [userType, setUserType] = useState()

	const orgDetails = useSelector((state) => state.orgDetails)
	const { org } = orgDetails

	// User login details from state
	const userLogin = useSelector((state) => state.userLogin)
	const { userInfo: userCredInfo } = userLogin

	// User details from state
	const { user } = useSelector((state) => state.userDetails)

	const userMembershipListOrg = useSelector(
		(state) => state.userMembershipListOrg
	)
	const { userMemberships } = userMembershipListOrg

	useEffect(() => {
		if (!org?._id || org?.slug !== orgSlug) {
			dispatch(getOrgDetails(orgSlug))
		}
		if (org?._id && org?.slug === orgSlug) {
			dispatch(listOrgUserMemberships(org._id))
		}
	}, [orgSlug, org, dispatch])

	useEffect(() => {
		if (!userCredInfo) {
			history.push(`/login?redirect=org/${orgSlug}/manage`)
		}
		if (!user?._id) {
			dispatch(getUserDetails('profile'))
		}
		if (user?._id) {
			const userMembership = user?.memberships?.find(
				(membership) => membership.org == org?._id
			)
			const myUserType = userCredInfo.isAdmin
				? 'admin'
				: userMembership?.memberType
			setUserType(myUserType)
			if (!(myUserType === 'admin' || myUserType === 'manager')) {
				history.push('/')
			}
		}
	}, [dispatch, history, userCredInfo, user, org])

	//Dashboard Figures
	const activeUserMemberships = userMemberships?.filter((userMembership) => {
		return userMembership.status === 'Paid in full'
	})
	const collectedActiveMemberships = sum(
		userMemberships?.filter((userMembership) => {
			return userMembership.status !== 'Open'
		}),
		'totalAmountPaid'
	)
	const orgEarningsActiveMemberships = sum(
		userMemberships?.filter((userMembership) => {
			return userMembership.status === 'Paid in full'
		}),
		'basePrice'
	)

	const orgPayouts = org?.payouts ? sum(org?.payouts, 'amount') : 0
	const orgRemaining = orgEarningsActiveMemberships - orgPayouts
	//End Dashboard Figures

	function sum(arr, key) {
		let res
		if (arr?.length) {
			res = arr.reduce((a, b) => a + (b[key] || 0), 0)
		}
		return res
	}

	let userMembershipsExport = activeUserMemberships?.map(
		({
			membershipProgram: { name: membership, taxRate },
			updatedAt: lastUpdated,
			status,
			basePrice,
			taxPrice,
			totalPrice,
			totalAmountPaid: totalCollected,
			totalAmountRemaining: totalRemaining,
			user: { name: user, email, phone },
		}) => {
			const totalOrgEarnings = totalCollected / (1 + (taxRate || 0))
			const result = {
				membership,
				user,
				email,
				phone,
				status,
				totalOrgEarnings,
				lastUpdated,
			}

			// Conditionally add basePrice, taxPrice, and totalCollected if userCredInfo.isAdmin is true
			if (userCredInfo.isAdmin) {
				result.basePrice = basePrice
				result.taxPrice = taxPrice
				result.totalPrice = totalPrice
				result.totalCollected = totalCollected
				result.totalRemaining = totalRemaining
			}

			return result
		}
	)

	return (
		<>
			{/* <DetailHeader /> */}
			<Container className='my-4'>
				{!userMemberships || org?.slug !== orgSlug ? (
					<Loader />
				) : userMemberships.length > 0 ? (
					<>
						<Row className='align-items-center'>
							<Col>
								<h1>Memberships</h1>
							</Col>
							{userMembershipsExport && (
								<Col className='text-right'>
									<CSVLink
										data={userMembershipsExport}
										filename={`Memberships - ${org?.name}.csv`}
									>
										<Button className='my-3'>Export Memberships</Button>
									</CSVLink>
								</Col>
							)}
						</Row>
						<Row>
							<Col lg='3' md='6' sm='12'>
								<Card className='my-2' bg='light'>
									<Card.Header>Active Memberships</Card.Header>
									<Card.Body>
										<Card.Title>{activeUserMemberships.length}</Card.Title>
									</Card.Body>
								</Card>
							</Col>

							{userCredInfo.isAdmin && (
								<Col lg='3' md='6' sm='12'>
									<Card className='my-2' bg='light'>
										<Card.Header>Total Collected</Card.Header>
										<Card.Body>
											<Card.Title>
												$
												{collectedActiveMemberships
													? collectedActiveMemberships.toFixed(2)
													: Number(0).toFixed(2)}
											</Card.Title>
										</Card.Body>
									</Card>
								</Col>
							)}

							<Col lg='3' md='6' sm='12'>
								<Card className='my-2' bg='light'>
									<Card.Header>Total Org Earnings</Card.Header>
									<Card.Body>
										<Card.Title>
											$
											{orgEarningsActiveMemberships
												? orgEarningsActiveMemberships?.toFixed(2)
												: Number(0).toFixed(2)}
										</Card.Title>
									</Card.Body>
								</Card>
							</Col>

							<Col lg='3' md='6' sm='12'>
								<Card className='my-2' bg='light'>
									<Card.Header>Total Org Payouts Issued</Card.Header>
									<Card.Body>
										<Card.Title>${orgPayouts?.toFixed(2)}</Card.Title>
									</Card.Body>
								</Card>
							</Col>

							{!userCredInfo.isAdmin && (
								<Col lg='3' md='6' sm='12'>
									<Card className='my-2' bg='light'>
										<Card.Header>Total Org Payouts Remaining</Card.Header>
										<Card.Body>
											<Card.Title>
												$
												{orgRemaining
													? orgRemaining.toFixed(2)
													: Number(0).toFixed(2)}
											</Card.Title>
										</Card.Body>
									</Card>
								</Col>
							)}
						</Row>

						<Table striped bordered hover responsive className='table-sm'>
							<thead>
								<tr>
									<th>MEMBERSHIP</th>
									<th>USER</th>
									<th>EMAIL</th>
									<th>PHONE</th>
									<th>STATUS</th>
									{userCredInfo.isAdmin ? <th>TOTAL PRICE</th> : ''}
									{userCredInfo.isAdmin ? <th>TOTAL COLLECTED</th> : ''}
									<th>TOTAL ORG EARNINGS</th>
									{userCredInfo.isAdmin ? <th></th> : ''}
								</tr>
							</thead>
							<tbody>
								{activeUserMemberships.map((userMembership) => (
									<tr key={userMembership._id}>
										<td>{userMembership?.membershipProgram?.name}</td>
										<td>
											{userMembership?.user && userMembership?.user?.name}
										</td>
										<td>{userMembership?.user?.email}</td>
										<td>{userMembership?.user?.phone}</td>
										<td>{userMembership?.status}</td>
										{userCredInfo.isAdmin ? (
											<td>${userMembership?.totalPrice}</td>
										) : (
											''
										)}
										{userCredInfo.isAdmin ? (
											<td>${userMembership?.totalAmountPaid}</td>
										) : (
											''
										)}
										<td>
											$
											{(
												userMembership?.totalAmountPaid /
												(1 + userMembership?.membershipProgram?.taxRate)
											).toFixed(2)}
										</td>

										{userCredInfo.isAdmin ? (
											<td>
												<LinkContainer
													to={`/org/${orgSlug}/${userMembership?.membershipProgram?.name}/membership/${userMembership?._id}`}
												>
													<Button variant='light' className='btn-sm'>
														Details
													</Button>
												</LinkContainer>
											</td>
										) : (
											''
										)}
									</tr>
								))}
							</tbody>
						</Table>
					</>
				) : (
					<h2>No memberships have been purchased</h2>
				)}
			</Container>
		</>
	)
}

export default OrgManageScreen
