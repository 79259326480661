import axios from 'axios'
import {
	MEMBERSHIP_FLOW_SET_MEMBERSHIP_PROGRAM,
	MEMBERSHIP_FLOW_SET_MEMBERSHIP_CLUB,
} from '../constants/membershipFlowConstants'

export const setMembershipFlow =
	(membershipProgramId, orgSlug) => async (dispatch, getState) => {
		// Dispatch actions to update the state
		await dispatch({
			type: MEMBERSHIP_FLOW_SET_MEMBERSHIP_PROGRAM,
			payload: membershipProgramId,
		})

		await dispatch({
			type: MEMBERSHIP_FLOW_SET_MEMBERSHIP_CLUB,
			payload: orgSlug,
		})

		// Now get the updated state and store it in localStorage
		const {
			membershipFlow: { flowMembershipProgram, flowMembershipOrg },
		} = getState()

		localStorage.setItem(
			'flowMembershipProgram',
			JSON.stringify(flowMembershipProgram)
		)
		localStorage.setItem('flowMembershipOrg', JSON.stringify(flowMembershipOrg))
	}
