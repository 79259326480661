import React from 'react'
import { useSelector } from 'react-redux'
import { Route, useLocation } from 'react-router-dom'
import { LinkContainer } from 'react-router-bootstrap'
import { Navbar, Nav, Container } from 'react-bootstrap'
import SearchBox from './SearchBox'

const SubHeader = () => {
	const userLogin = useSelector((state) => state.userLogin)
	const { userInfo } = userLogin
	const location = useLocation()

	return (
		<>
			{userInfo && userInfo.isAdmin && (
				<Navbar
					style={{ backgroundColor: '#f38f62' }}
					variant='dark'
					expand='sm'
					collapseOnSelect
					className='navbar'
				>
					<Container>
						<LinkContainer to='/admin/' style={{ color: '#000' }}>
							<Navbar.Brand>Admin</Navbar.Brand>
						</LinkContainer>
						<Navbar.Toggle aria-controls='basic-navbar-nav' />
						<Navbar.Collapse id='basic-navbar-nav'>
							{location.pathname.startsWith('/admin/userlist') && (
								<Route
									render={({ history }) => <SearchBox history={history} />}
								/>
							)}
							<Nav className='ml-auto'>
								<LinkContainer to='/admin/userlist'>
									<Nav.Item>Users</Nav.Item>
								</LinkContainer>
								<LinkContainer to='/admin/experienceList'>
									<Nav.Item>Experiences</Nav.Item>
								</LinkContainer>
								<LinkContainer to='/admin/orgList'>
									<Nav.Item>Orgs</Nav.Item>
								</LinkContainer>
							</Nav>
						</Navbar.Collapse>
					</Container>
				</Navbar>
			)}
		</>
	)
}

export default SubHeader
