import React, { useState, useEffect } from 'react'
import { Form, Button, Row, ListGroup, Container } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import FormContainer from '../../../../components/FormContainer'
import {
	getUserDetails,
	updateUserProfile,
} from '../../../../services/actions/userActions'
import { getOrgDetails } from '../../../../services/actions/orgActions'
import { USER_UPDATE_PROFILE_RESET } from '../../../../services/constants/userConstants'
import MembershipFlowSteps from '../../../../components/MembershipFlowSteps/MembershipFlowSteps'

const MemberInfoScreen = ({ history }) => {
	const dispatch = useDispatch()
	const [name, setName] = useState('')
	const [email, setEmail] = useState('')
	const [phone, setPhone] = useState('')
	const [dateOfBirth, setDateOfBirth] = useState('')
	const [activityStyle, setActivityStyle] = useState('')
	const [shirtSize, setShirtSize] = useState('Select a size...')
	const userUpdateProfile = useSelector((state) => state.userUpdateProfile)
	const { success } = userUpdateProfile

	// Get basic details of logged in user
	const userLogin = useSelector((state) => state.userLogin)
	const { userInfo } = userLogin

	// Get programId and orgSlug from state
	const membershipFlow = useSelector((state) => state.membershipFlow)
	const { flowMembershipProgram, flowMembershipOrg } = membershipFlow

	// Org details from state
	const orgDetails = useSelector((state) => state.orgDetails)
	const { loading, error: orgDetailsError, org } = orgDetails

	// Get all details of membership program being purchased
	const selectedMembershipProgram = org?.membershipPrograms?.find(
		(program) => program._id === flowMembershipProgram
	)

	// Get all user details
	const userDetails = useSelector((state) => state.userDetails)
	const { user } = userDetails

	useEffect(() => {
		if (!Object.keys(org).length) {
			dispatch(getOrgDetails(flowMembershipOrg))
		}
	}, [org, flowMembershipOrg, dispatch])

	useEffect(() => {
		if (!userInfo) {
			history.push('/login')
		} else {
			if (!user?._id || success) {
				dispatch({ type: USER_UPDATE_PROFILE_RESET })
				dispatch(getUserDetails('profile'))
			} else {
				// Set the form fields with user details
				setName(user.name)
				setEmail(user.email)
				setPhone(user.phone)
				setDateOfBirth(user.dateOfBirth)
				setActivityStyle(user.activityStyle)
				setShirtSize(user.shirtSize)

				// Check if the user has a membership that matches flowMembershipProgram
				const matchedMembership = user.memberships.find(
					(membership) => membership.membershipProgram === flowMembershipProgram
				)

				// If a matching membership is found, redirect to the desired URL
				if (matchedMembership) {
					history.push(
						`/org/${flowMembershipOrg}/${matchedMembership.membershipProgram}/membership/${matchedMembership._id}`
					)
				}
			}
		}
	}, [dispatch, history, userInfo, user, success, flowMembershipProgram])

	const updateUser = () => {
		dispatch(
			updateUserProfile({
				id: user._id,
				name: name,
				email: email,
				phone: phone,
				dateOfBirth: dateOfBirth,
				activityStyle: activityStyle,
				shirtSize: shirtSize,
			})
		)
	}

	const submitHandler = (e) => {
		e.preventDefault()
		updateUser()
		history.push(
			`/org/${flowMembershipOrg}/${flowMembershipProgram}/review-membership`
		)
	}

	return (
		<Container>
			{!selectedMembershipProgram ||
			Object.keys(selectedMembershipProgram)?.length === 0 ? (
				<Container>
					You are not signing up for any membership<Link to='/'>Go Home</Link>
				</Container>
			) : (
				<div className='my-4'>
					<Container
						className='my-4'
						style={{
							boxShadow: '0 0 10px #b0b0b0',
						}}
					>
						<ListGroup variant='flush'>
							<ListGroup.Item className='p-2 mt-4'>
								<h1 className='text-center'>{org.name}</h1>
								<h5 className='text-center'>
									{' '}
									{`${selectedMembershipProgram.name}`}
								</h5>
							</ListGroup.Item>
						</ListGroup>

						<Row
							className='justify-content-center'
							style={{ backgroundColor: '#F3F3F3' }}
						>
							<MembershipFlowSteps org={org} membershipOptions memberInfo />
						</Row>
					</Container>

					<FormContainer>
						<h1>Member Information</h1>
						<Form onSubmit={submitHandler}>
							<Form.Group controlId='name'>
								<Form.Label>Full Name</Form.Label>
								<Form.Control
									type='text'
									placeholder='Enter full name'
									value={name}
									onChange={(e) => setName(e.target.value)}
									required={true}
									disabled={true}
								></Form.Control>
							</Form.Group>

							<Form.Group controlId='email'>
								<Form.Label>Email Address</Form.Label>
								<Form.Control
									type='email'
									placeholder='Enter email'
									value={email}
									onChange={(e) => setEmail(e.target.value)}
									required={true}
									disabled={true}
								></Form.Control>
							</Form.Group>

							<Form.Group controlId='phone'>
								<Form.Label>Phone</Form.Label>
								<Form.Control
									type='phone'
									placeholder='Enter phone'
									value={phone}
									onChange={(e) => setPhone(e.target.value)}
									required={true}
								></Form.Control>
							</Form.Group>

							<Form.Group controlId='dateOfBirth'>
								<Form.Label>Date of Birth</Form.Label>
								<Form.Control
									type='date'
									placeholder='Enter date of birth'
									value={dateOfBirth && dateOfBirth.substring(0, 10)}
									onChange={(e) => setDateOfBirth(e.target.value)}
									required={true}
								></Form.Control>
							</Form.Group>

							<Form.Group controlId='shirtSize'>
								<Form.Label>T-Shirt Size</Form.Label>
								<Form.Control
									as='select'
									type='text'
									value={shirtSize}
									required={true}
									onChange={(e) => setShirtSize(e.target.value)}
								>
									<option disabled>Select a size...</option>
									<option>Adult XXL</option>
									<option>Adult XL</option>
									<option>Adult L</option>
									<option>Adult M</option>
									<option>Adult S</option>
								</Form.Control>
							</Form.Group>

							<Row className='mt-3 p-2'>
								<LinkContainer
									to={`/org/${flowMembershipOrg}/membership-options`}
								>
									<Button className='mr-1 btn-secondary' type='button'>
										Back
									</Button>
								</LinkContainer>

								<Button type='submit' variant='primary'>
									Continue
								</Button>
							</Row>
						</Form>
					</FormContainer>
				</div>
			)}
		</Container>
	)
}

export default MemberInfoScreen
