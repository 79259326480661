import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Container, Table, Button, Card, Col, Row } from 'react-bootstrap'
import { CSVLink } from 'react-csv'
import DetailHeader from '../../components/DetailHeader'
import Loader from '../../components/Loader'
import { getUserDetails } from '../../services/actions/userActions'
import { getExperienceDetails } from '../../services/actions/experienceActions'
import { listExperienceLeads } from '../../services/actions/leadActions'

const ExperienceLeadsScreen = ({ match, history }) => {
	const dispatch = useDispatch()

	const experienceId = match.params.id

	const experienceDetails = useSelector((state) => state.experienceDetails)
	const { experience } = experienceDetails

	const userLogin = useSelector((state) => state.userLogin)
	const { userInfo } = userLogin

	const leadListExperience = useSelector((state) => state.leadListExperience)
	const { leads } = leadListExperience

	useEffect(() => {
		if (!experience?._id) {
			dispatch(getExperienceDetails(experienceId))
		}
		if (experience?._id) {
			dispatch(listExperienceLeads(experienceId))
		}
	}, [experienceId, experience, dispatch])

	useEffect(() => {
		if (!userInfo) {
			history.push('/')
		} else if (!userInfo.isAdmin) {
			history.push('/')
		} else if (!userInfo || !userInfo.name || !leads) {
			dispatch(getUserDetails('profile'))
		}
	}, [dispatch, history, userInfo, leads])

	let leadExport = leads?.map(
		({ experience: { name: experience }, email }) => ({
			experience,
			email,
		})
	)

	return (
		<>
			<DetailHeader />
			<Container className='my-4'>
				<Row className='align-items-center'>
					<Col>
						<h1>Leads</h1>
					</Col>
					{leadExport && (
						<Col className='text-right'>
							<CSVLink
								data={leadExport}
								filename={`Leads - ${experience?.name}.csv`}
							>
								<Button className='my-3'>Export Leads</Button>
							</CSVLink>
						</Col>
					)}
				</Row>

				{!leads ? (
					<Loader />
				) : leads.length > 0 ? (
					<>
						<Row>
							<Col lg='3' md='12'>
								<Card className='my-2' bg='light'>
									<Card.Header>Total Leads</Card.Header>
									<Card.Body>
										<Card.Title>{leads?.length}</Card.Title>
									</Card.Body>
								</Card>
							</Col>
						</Row>

						<Table striped bordered hover responsive className='table-sm'>
							<thead>
								<tr>
									<th>EMAIL</th>
								</tr>
							</thead>
							<tbody>
								{leads.map((lead) => (
									<tr key={lead._id}>
										<td>{lead.email}</td>
									</tr>
								))}
							</tbody>
						</Table>
					</>
				) : (
					<h2>No leads</h2>
				)}
			</Container>
		</>
	)
}

export default ExperienceLeadsScreen
