export const EXPERIENCE_LIST_REQUEST = 'EXPERIENCE_LIST_REQUEST'
export const EXPERIENCE_LIST_SUCCESS = 'EXPERIENCE_LIST_SUCCESS'
export const EXPERIENCE_LIST_FAIL = 'EXPERIENCE_LIST_FAIL'

export const EXPERIENCE_DETAILS_REQUEST = 'EXPERIENCE_DETAILS_REQUEST'
export const EXPERIENCE_DETAILS_SUCCESS = 'EXPERIENCE_DETAILS_SUCCESS'
export const EXPERIENCE_DETAILS_FAIL = 'EXPERIENCE_DETAILS_FAIL'
export const EXPERIENCE_DETAILS_RESET = 'EXPERIENCE_DETAILS_RESET'

export const EXPERIENCE_DELETE_REQUEST = 'EXPERIENCE_DELETE_REQUEST'
export const EXPERIENCE_DELETE_SUCCESS = 'EXPERIENCE_DELETE_SUCCESS'
export const EXPERIENCE_DELETE_FAIL = 'EXPERIENCE_DELETE_FAIL'

export const EXPERIENCE_CREATE_REQUEST = 'EXPERIENCE_CREATE_REQUEST'
export const EXPERIENCE_CREATE_SUCCESS = 'EXPERIENCE_CREATE_SUCCESS'
export const EXPERIENCE_CREATE_FAIL = 'EXPERIENCE_CREATE_FAIL'
export const EXPERIENCE_CREATE_RESET = 'EXPERIENCE_CREATE_RESET'

export const EXPERIENCE_UPDATE_REQUEST = 'EXPERIENCE_UPDATE_REQUEST'
export const EXPERIENCE_UPDATE_SUCCESS = 'EXPERIENCE_UPDATE_SUCCESS'
export const EXPERIENCE_UPDATE_FAIL = 'EXPERIENCE_UPDATE_FAIL'
export const EXPERIENCE_UPDATE_RESET = 'EXPERIENCE_UPDATE_RESET'

export const EXPERIENCE_CREATE_ITINERARYITEM_REQUEST =
	'EXPERIENCE_CREATE_ITINERARYITEM_REQUEST'
export const EXPERIENCE_CREATE_ITINERARYITEM_SUCCESS =
	'EXPERIENCE_CREATE_ITINERARYITEM_SUCCESS'
export const EXPERIENCE_CREATE_ITINERARYITEM_FAIL =
	'EXPERIENCE_CREATE_ITINERARYITEM_FAIL'
export const EXPERIENCE_CREATE_ITINERARYITEM_RESET =
	'EXPERIENCE_CREATE_ITINERARYITEM_RESET'

export const EXPERIENCE_CREATE_TRIPOPTION_REQUEST =
	'EXPERIENCE_CREATE_TRIPOPTION_REQUEST'
export const EXPERIENCE_CREATE_TRIPOPTION_SUCCESS =
	'EXPERIENCE_CREATE_TRIPOPTION_SUCCESS'
export const EXPERIENCE_CREATE_TRIPOPTION_FAIL =
	'EXPERIENCE_CREATE_TRIPOPTION_FAIL'
export const EXPERIENCE_CREATE_TRIPOPTION_RESET =
	'EXPERIENCE_CREATE_TRIPOPTION_RESET'
