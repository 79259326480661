import React from 'react'
import { Helmet } from 'react-helmet'

const Meta = ({
	title,
	description,
	keywords,
	mobileTitle,
	mobileImage,
	mobileUrl,
}) => {
	return (
		<Helmet>
			<title>{title}</title>
			<meta property='description' content={description} />
			<meta property='keywords' content={keywords} />
			<meta property='og:title' content={mobileTitle} />
			<meta property='og:description' content={mobileTitle} />
			<meta property='og:image' content={mobileImage} />
			<meta property='og:type' content='website' />
			<meta property='og:url' content={mobileUrl} />
		</Helmet>
	)
}

Meta.defaultProps = {
	title: 'I Do Tours',
	description: '',
	keywords: '',
}

export default Meta
