import React, { useState, useEffect } from 'react'
import { Button } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
import useExperienceLogic from '../services/hooks/useExperienceLogic'

const Experience = ({ order, user }) => {
	const { experience } = order

	const { enableButton, buttonLabel } = useExperienceLogic(experience, user)

	return (
		<>
			{experience && (
				<div
					style={{
						boxShadow: '0px 0px 15px  #f5f5f5',
					}}
					className='my-2'
				>
					<LinkContainer to={`/experiences/${experience._id}`}>
						<li className='cards__item m-0'>
							<div className='cards__item__link'>
								<figure
									className='cards__item__pic-wrap-no-box'
									data-category={experience.type}
								>
									<img
										className='cards__item__img'
										alt='Travel Pic'
										src={experience.image}
									/>
								</figure>
								<div className='cards__item__info py-2'>
									<h5 className='cards__item__text mb-2'>{experience.name}</h5>
								</div>
							</div>
						</li>
					</LinkContainer>
					<Button
						href={`/experiences/${experience._id}`}
						className='btn-block'
						type='button'
					>
						View Experience
					</Button>
				</div>
			)}
		</>
	)
}

export default Experience
