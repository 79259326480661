import React from 'react'
import { useSelector } from 'react-redux'
import { Image } from 'react-bootstrap'

const Hero = ({ height }) => {
	const experienceDetails = useSelector((state) => state.experienceDetails)
	const { experience } = experienceDetails
	const { image } = experience

	return (
		<div style={{ zIndex: -1, position: 'relative' }}>
			<Image
				src={image}
				className='mb-4 hero'
				style={{
					objectFit: 'cover',
					objectPosition: 'center',
					height: `${height}`,
					width: '100%',
					opacity: '.8',
				}}
			></Image>
		</div>
	)
}

Hero.defaultProps = {
	height: '20vh',
}

export default Hero
