import React from 'react'
import { useSelector } from 'react-redux'
import CardItem from './CardItem'
import './Gallery.css'
import { Row, Col, ListGroup, Container } from 'react-bootstrap'
import Message from '../components/Message'
import Loader from '../components/Loader'

const Gallery = ({ title, type }) => {
	const experienceDetails = useSelector((state) => state.experienceDetails)
	const { loading, error, experience } = experienceDetails

	const orgDetails = useSelector((state) => state.orgDetails)
	const { org } = orgDetails
	const { highlights } = org

	const { itinerary, destination } = experience

	const galleryItems =
		type === 'itinerary'
			? itinerary
			: type === 'destination'
			? destination
			: type === 'highlights'
			? highlights
			: ''

	return (
		<div className='gallery'>
			<Container>
				<Row>
					<Col md={12} className='gallery-container'>
						<ListGroup variant='flush'>
							{loading ? (
								<Loader />
							) : error ? (
								<Message variant='danger'>{error}</Message>
							) : itinerary || destination || highlights ? (
								<ListGroup.Item>
									<h2>{title}</h2>
									<div className='cards__wrapper'>
										<ul className='cards__items'>
											{galleryItems.map((item) => (
												<CardItem
													key={item._id}
													src={item.image}
													title={item.title}
													desc={item.description}
													label={type === 'itinerary' ? item.dayName : ''}
													labelVisibility={
														type === 'itinerary' ? 'visible' : 'hidden'
													}
													type={type}
												/>
											))}
										</ul>
									</div>
								</ListGroup.Item>
							) : (
								'no'
							)}
						</ListGroup>
					</Col>
				</Row>
			</Container>
		</div>
	)
}

Gallery.defaultProps = {
	title: 'Gallery',
}

export default Gallery
