import axios from 'axios'

import {
	ORDER_CREATE_REQUEST,
	ORDER_CREATE_SUCCESS,
	ORDER_CREATE_FAIL,
	ORDER_DETAILS_REQUEST,
	ORDER_DETAILS_SUCCESS,
	ORDER_DETAILS_FAIL,
	ORDER_PAY_REQUEST,
	ORDER_PAY_FAIL,
	ORDER_PAY_SUCCESS,
	ORDER_LIST_MY_FAIL,
	ORDER_LIST_MY_SUCCESS,
	ORDER_LIST_MY_REQUEST,
	ORDER_LIST_EXPERIENCE_FAIL,
	ORDER_LIST_EXPERIENCE_SUCCESS,
	ORDER_LIST_EXPERIENCE_REQUEST,
	ORDER_LIST_USER_FAIL,
	ORDER_LIST_USER_SUCCESS,
	ORDER_LIST_USER_REQUEST,
	ORDER_LIST_REQUEST,
	ORDER_LIST_SUCCESS,
	ORDER_LIST_FAIL,
	ORDER_LIST_EXPERIENCE_TRAVELERS_FAIL,
	ORDER_LIST_EXPERIENCE_TRAVELERS_SUCCESS,
	ORDER_LIST_EXPERIENCE_TRAVELERS_REQUEST,
	ORDER_DELETE_SUCCESS,
	ORDER_DELETE_REQUEST,
	ORDER_DELETE_FAIL,
	ORDER_UPDATE_REQUEST,
	ORDER_UPDATE_FAIL,
	ORDER_UPDATE_SUCCESS,
} from '../constants/orderConstants'

export const createOrder = (order) => async (dispatch, getState) => {
	try {
		dispatch({
			type: ORDER_CREATE_REQUEST,
		})

		const {
			userLogin: { userInfo },
		} = getState()

		const config = {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${userInfo.token}`,
			},
		}

		const { data } = await axios.post(`/api/orders`, order, config)

		dispatch({
			type: ORDER_CREATE_SUCCESS,
			payload: data,
		})
	} catch (error) {
		dispatch({
			type: ORDER_CREATE_FAIL,
			payload:
				error.response && error.response.data.message
					? error.response.data.message
					: error.message,
		})
	}
}

export const getOrderDetails = (id) => async (dispatch, getState) => {
	try {
		dispatch({
			type: ORDER_DETAILS_REQUEST,
		})

		const {
			userLogin: { userInfo },
		} = getState()

		const config = {
			headers: {
				Authorization: `Bearer ${userInfo.token}`,
			},
		}

		const { data } = await axios.get(`/api/orders/${id}`, config)

		dispatch({
			type: ORDER_DETAILS_SUCCESS,
			payload: data,
		})
	} catch (error) {
		dispatch({
			type: ORDER_DETAILS_FAIL,
			payload:
				error.response && error.response.data.message
					? error.response.data.message
					: error.message,
		})
	}
}

export const payOrder =
	(orderId, paymentResult) => async (dispatch, getState) => {
		try {
			dispatch({
				type: ORDER_PAY_REQUEST,
			})

			const {
				userLogin: { userInfo },
			} = getState()

			const config = {
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${userInfo.token}`,
				},
			}

			const { data } = await axios.put(
				`/api/orders/${orderId}/pay`,
				paymentResult,
				config
			)

			dispatch({
				type: ORDER_PAY_SUCCESS,
				payload: data,
			})
		} catch (error) {
			dispatch({
				type: ORDER_PAY_FAIL,
				payload:
					error.response && error.response.data.message
						? error.response.data.message
						: error.message,
			})
		}
	}

export const listMyOrders = () => async (dispatch, getState) => {
	try {
		dispatch({
			type: ORDER_LIST_MY_REQUEST,
		})

		const {
			userLogin: { userInfo },
		} = getState()

		const config = {
			headers: {
				Authorization: `Bearer ${userInfo.token}`,
			},
		}

		const { data } = await axios.get(`/api/orders/myorders`, config)

		dispatch({
			type: ORDER_LIST_MY_SUCCESS,
			payload: data,
		})
	} catch (error) {
		dispatch({
			type: ORDER_LIST_MY_FAIL,
			payload:
				error.response && error.response.data.message
					? error.response.data.message
					: error.message,
		})
	}
}

export const listExperienceOrders =
	(experienceId) => async (dispatch, getState) => {
		try {
			dispatch({
				type: ORDER_LIST_EXPERIENCE_REQUEST,
			})

			const {
				userLogin: { userInfo },
			} = getState()

			const config = {
				headers: {
					Authorization: `Bearer ${userInfo.token}`,
				},
			}

			const { data } = await axios.get(
				`/api/orders/experienceorders/${experienceId}`,
				config
			)

			dispatch({
				type: ORDER_LIST_EXPERIENCE_SUCCESS,
				payload: data,
			})
		} catch (error) {
			dispatch({
				type: ORDER_LIST_EXPERIENCE_FAIL,
				payload:
					error.response && error.response.data.message
						? error.response.data.message
						: error.message,
			})
		}
	}

export const listUserOrders = (userId) => async (dispatch, getState) => {
	try {
		dispatch({
			type: ORDER_LIST_USER_REQUEST,
		})

		const {
			userLogin: { userInfo },
		} = getState()

		const config = {
			headers: {
				Authorization: `Bearer ${userInfo.token}`,
			},
		}

		const { data } = await axios.get(`/api/orders/userorders/${userId}`, config)

		dispatch({
			type: ORDER_LIST_USER_SUCCESS,
			payload: data,
		})
	} catch (error) {
		dispatch({
			type: ORDER_LIST_USER_FAIL,
			payload:
				error.response && error.response.data.message
					? error.response.data.message
					: error.message,
		})
	}
}

export const listOrders = () => async (dispatch, getState) => {
	try {
		dispatch({
			type: ORDER_LIST_REQUEST,
		})

		const {
			userLogin: { userInfo },
		} = getState()

		const config = {
			headers: {
				Authorization: `Bearer ${userInfo.token}`,
			},
		}

		const { data } = await axios.get(`/api/orders`, config)

		dispatch({
			type: ORDER_LIST_SUCCESS,
			payload: data,
		})
	} catch (error) {
		dispatch({
			type: ORDER_LIST_FAIL,
			payload:
				error.response && error.response.data.message
					? error.response.data.message
					: error.message,
		})
	}
}

export const listExperienceTravelers =
	(experienceId) => async (dispatch, getState) => {
		try {
			dispatch({
				type: ORDER_LIST_EXPERIENCE_TRAVELERS_REQUEST,
			})

			const {
				userLogin: { userInfo },
			} = getState()

			const config = {
				headers: {
					Authorization: `Bearer ${userInfo.token}`,
				},
			}

			const { data } = await axios.get(
				`/api/orders/${experienceId}/travelers`,
				config
			)

			dispatch({
				type: ORDER_LIST_EXPERIENCE_TRAVELERS_SUCCESS,
				payload: data,
			})
		} catch (error) {
			dispatch({
				type: ORDER_LIST_EXPERIENCE_TRAVELERS_FAIL,
				payload:
					error.response && error.response.data.message
						? error.response.data.message
						: error.message,
			})
		}
	}

export const deleteOrder = (id) => async (dispatch, getState) => {
	try {
		dispatch({
			type: ORDER_DELETE_REQUEST,
		})

		const {
			userLogin: { userInfo },
		} = getState()

		const config = {
			headers: {
				Authorization: `Bearer ${userInfo.token}`,
			},
		}

		await axios.delete(`/api/orders/${id}`, config)

		dispatch({
			type: ORDER_DELETE_SUCCESS,
		})

		window.alert('Success')
	} catch (error) {
		dispatch({
			type: ORDER_DELETE_FAIL,
			payload:
				error.response && error.response.data.message
					? error.response.data.message
					: error.message,
		})
		window.alert(error.response.data.message)
	}
}

export const updateOrderDetails =
	(orderId, order) => async (dispatch, getState) => {
		try {
			dispatch({
				type: ORDER_UPDATE_REQUEST,
			})

			const {
				userLogin: { userInfo },
			} = getState()

			const config = {
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${userInfo.token}`,
				},
			}

			const { data } = await axios.put(
				`/api/orders/${orderId}/update`,
				order,
				config
			)

			dispatch({
				type: ORDER_UPDATE_SUCCESS,
				payload: data,
			})
		} catch (error) {
			dispatch({
				type: ORDER_UPDATE_FAIL,
				payload:
					error.response && error.response.data.message
						? error.response.data.message
						: error.message,
			})
		}
	}
