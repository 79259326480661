import React from 'react'
import { Image } from 'react-bootstrap'

const HeroImage = ({ height, image }) => {
	return (
		<div style={{ zIndex: -1, position: 'relative' }}>
			<Image
				src={image}
				className='mb-4 hero'
				style={{
					objectFit: 'cover',
					objectPosition: 'center',
					height: `${height}`,
					width: '100%',
					opacity: '.8',
				}}
			></Image>
		</div>
	)
}

HeroImage.defaultProps = {
	height: '20vh',
}

export default HeroImage
