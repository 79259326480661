import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Form, Button, Row, ListGroup, Container, Col } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import CheckoutSteps from '../components/CheckoutSteps'
import Message from '../components/Message'
import { savePaymentStructure } from '../services/actions/flowActions'
import { createOrder } from '../services/actions/orderActions'

const PaymentScreen = ({ history }) => {
	const dispatch = useDispatch()

	const [paymentStructure, setPaymentStructure] = useState('Pay deposit first')

	const flow = useSelector((state) => state.flow)
	const { flowItem, orderInfo } = flow
	const { totalOrderAmount, depositPrice } = orderInfo

	const [firstPaymentPrice, setFirstPaymentPrice] = useState(
		flowItem.depositPrice
	)

	//Date Logic
	const currentYear = new Date().getFullYear()

	const startYear = new Date(flowItem?.experienceStartDate).getFullYear()

	const startMonth =
		flowItem &&
		new Date(flowItem?.experienceStartDate).toLocaleString('default', {
			month: 'short',
		})

	const startDay = new Date(flowItem?.experienceStartDate).getDate()

	const endYear = new Date(flowItem?.experienceEndDate).getFullYear()

	const endMonth =
		flowItem &&
		new Date(flowItem?.experienceEndDate).toLocaleString('default', {
			month: 'short',
		})

	const endDay = flowItem && new Date(flowItem?.experienceEndDate).getDate()

	const startDate =
		flowItem &&
		startMonth +
			' ' +
			startDay +
			(startYear !== endYear ? ', ' + startYear : '')

	const endDate =
		(startMonth === endMonth ? endDay : endMonth + ' ' + endDay) +
		(endYear !== startYear || endYear !== currentYear ? ', ' + endYear : '')

	const fullDate = flowItem && startDate + ' - ' + endDate
	//End Date Logic

	const orderCreate = useSelector((state) => state.orderCreate)
	const { order, success, error } = orderCreate

	useEffect(() => {
		if (success) {
			history.push(`/order/${order._id}`)
		}
	}, [history, success])

	const placeOrderHandler = (e) => {
		e.preventDefault()
		dispatch(savePaymentStructure(paymentStructure))

		dispatch(
			createOrder({
				experience: flow.flowItem.experience,
				paymentStructure: paymentStructure,
				firstPaymentPrice: firstPaymentPrice,
				taxPrice: flow.orderInfo.totalTaxesAmount,
				totalPrice: flow.orderInfo.totalOrderAmount,
				totalAmountRemaining: flow.orderInfo.totalOrderAmount,
				totalAmountPaid: 0,
				finalPaymentDueDate: flow.finalPaymentDueDate,
				addOns: flow.orderInfo.addOns,
				addOnsPrice: flow.orderInfo.addOnsPrice,
			})
		)
	}

	return (
		<Container>
			<div className='my-4'>
				{error && <Message variant='danger'>{error}</Message>}
				{flowItem.length === 0 ? (
					<Message>
						You have no active bookings <Link to='/'>Go Back</Link>
					</Message>
				) : (
					<>
						<Container
							className='my-4'
							style={{
								boxShadow: '0 0 10px #b0b0b0',
							}}
						>
							<ListGroup variant='flush'>
								<ListGroup.Item className='p-2 mt-4'>
									<h1 className='text-center'>{flowItem.name}</h1>
									<h5 className='text-center'>
										{' '}
										{`${flowItem.locationName} | ${fullDate}`}
									</h5>
								</ListGroup.Item>
							</ListGroup>

							<Row
								className='justify-content-center'
								style={{ backgroundColor: '#F3F3F3' }}
							>
								<CheckoutSteps
									review
									travelerInfo
									tripOptions
									paymentStructure
								/>
							</Row>
						</Container>
						<div className='my-4'>
							<h1>What works for you?</h1>
							<Form onSubmit={placeOrderHandler}>
								<Form.Group>
									<Form.Label as='legend'>
										Please select a payment structure
									</Form.Label>
									<Col>
										<Form.Check
											type='radio'
											label={`Put down $${depositPrice} deposit`}
											name='paymentStructure'
											id='depositOption'
											value='Pay deposit first'
											onChange={(e) => {
												setPaymentStructure(e.target.value)
												setFirstPaymentPrice(depositPrice)
											}}
											defaultChecked
										/>
										<Form.Check
											type='radio'
											label={`Pay experience price in full`}
											name='paymentStructure'
											id='fullOption'
											value='Pay in full'
											onChange={(e) => {
												setPaymentStructure(e.target.value)
												setFirstPaymentPrice(totalOrderAmount)
											}}
										/>
									</Col>
								</Form.Group>
								<Row className='mt-3 p-2'>
									<Button
										className='mr-1 btn-secondary'
										type='button'
										onClick={() => history.goBack()}
									>
										Back
									</Button>

									<Button type='submit' variant='primary'>
										Continue
									</Button>
								</Row>
							</Form>
						</div>{' '}
					</>
				)}
			</div>
		</Container>
	)
}

export default PaymentScreen
