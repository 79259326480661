import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
	Row,
	Col,
	ListGroup,
	Card,
	Button,
	Container,
	Badge,
	Form,
	Modal,
} from 'react-bootstrap'
import Message from '../../components/Message'
import Loader from '../../components/Loader'
import Gallery from '../../components/Gallery'
import Meta from '../../components/Meta'
import Hero from '../../components/Hero'
import { getExperienceDetails } from '../../services/actions/experienceActions'
import { getUserDetails } from '../../services/actions/userActions'
import { listExperienceTravelers } from '../../services/actions/orderActions'
import { register } from '../../services/actions/leadActions'
import useExperienceLogic from '../../services/hooks/useExperienceLogic'
import { displayExperienceDate } from '../../services/utils/dateFormats'
import '../../screens/ExperienceScreen/ExperienceScreen.css'

const ExperienceScreen = ({ history, match }) => {
	const dispatch = useDispatch()
	const [leadEmail, setLeadEmail] = useState()
	const [showModal, setShowModal] = useState(false)
	const [showPresaleModal, setShowPresaleModal] = useState(false)

	const experienceId = match.params.id

	// Experience details from state
	const experienceDetails = useSelector((state) => state.experienceDetails)
	const { loading, error, experience } = experienceDetails

	// User details from state
	const userDetails = useSelector((state) => state.userDetails)
	const { user } = userDetails

	// Experience travelers from state
	const experienceTravelers = useSelector((state) => state.experienceTravelers)
	const { orders: travelers } = experienceTravelers

	useEffect(() => {
		dispatch(getExperienceDetails(experienceId))
		dispatch(listExperienceTravelers(experienceId))
	}, [experienceId, dispatch])

	useEffect(() => {
		if (!user?._id) {
			dispatch(getUserDetails('profile'))
		}
	}, [user, dispatch])

	const { enableButton, buttonLabel, userOrderId } = useExperienceLogic(
		experience,
		user
	)

	// Lead from state
	const leadRegister = useSelector((state) => state.leadRegister)
	const { success: successLead } = leadRegister

	//Date Logic
	const currentYear = new Date().getFullYear()

	const onSaleYear =
		experience && new Date(experience?.onSaleDate).getFullYear()

	const onSaleMonth =
		experience &&
		new Date(experience?.onSaleDate).toLocaleString('default', {
			month: 'short',
		})

	const onSaleDate =
		experience &&
		onSaleMonth +
			' ' +
			new Date(experience?.onSaleDate).getDate() +
			(onSaleYear !== currentYear ? ', ' + onSaleYear : '')

	const startYear = new Date(experience?.experienceStartDate).getFullYear()

	const startMonth =
		experience &&
		new Date(experience?.experienceStartDate).toLocaleString('default', {
			month: 'short',
		})

	const startDay = new Date(experience?.experienceStartDate).getDate()

	const endYear = new Date(experience?.experienceEndDate).getFullYear()

	const endMonth =
		experience &&
		new Date(experience?.experienceEndDate).toLocaleString('default', {
			month: 'short',
		})

	const endDay = experience && new Date(experience?.experienceEndDate).getDate()

	const startDate =
		experience &&
		startMonth +
			' ' +
			startDay +
			(startYear !== endYear ? ', ' + startYear : '')

	const endDate =
		(startMonth === endMonth ? endDay : endMonth + ' ' + endDay) +
		(endYear !== startYear || endYear !== currentYear ? ', ' + endYear : '')

	const fullDate = experience && startDate + ' - ' + endDate
	//End Date Logic

	const addToFlowHandler = () => {
		if (userOrderId) {
			history.push(`/order/${userOrderId}`)
		} else {
			history.push(`/${match.params.id}/review`)
		}
	}

	const addLeadHandler = (e) => {
		if (leadEmail) {
			e.preventDefault()
			handleShowPresaleModal()
			dispatch(register(leadEmail, experience?._id))
			setLeadEmail('')
		}
	}

	const handleCloseModal = () => setShowModal(false)
	const handleShowModal = () => setShowModal(true)

	const handleClosePresaleModal = () => setShowPresaleModal(false)
	const handleShowPresaleModal = () => setShowPresaleModal(true)

	return (
		<>
			{loading ? (
				<Loader />
			) : error ? (
				<Message variant='danger'>{error}</Message>
			) : (
				<>
					<Hero height='65vh' />
					<Container>
						<Meta
							title={`I Do Tours | ${experience?.name}`}
							mobileTitle={`${experience?.name} by I Do Tours`}
							mobileImage={`${process.env.CLIENT_DOMAIN}/${experience?.SMSImage}`}
							mobileDescription={experience?.SMSImage}
							mobileUrl={`${process.env.CLIENT_DOMAIN}/experiences/${experience?._id}`}
						/>
						<Row
							className='mb-4 mr-1 ml-1 mt-n5'
							style={{
								boxShadow: '0 0 10px #b0b0b0',
								backgroundColor: '#fff',
							}}
						>
							<Col xl={8} lg={7}>
								<ListGroup variant='flush'>
									<ListGroup.Item className='p-4'>
										<h1>{experience?.name}</h1>
										<h5>
											{experience?.locationName} | {fullDate}
										</h5>
										{experience?.status === 'Presale' && (
											<h1>
												<Badge
													style={{ backgroundColor: 'black' }}
													variant='dark'
												>
													ON SALE {onSaleDate}
												</Badge>
											</h1>
										)}
									</ListGroup.Item>
								</ListGroup>
							</Col>
							<Col xl={4} lg={5} className='p-0'>
								<Card className=''>
									<ListGroup variant='flush'>
										<ListGroup.Item style={{ backgroundColor: '#F3F3F3' }}>
											<Row>
												<Col className='py-2' lg={6}>
													<h5 className='m-0'>Experience Price:</h5>
												</Col>
												<Col className='py-2' lg={6}>
													<h5>
														$
														{experience?.fullPriceWithoutTax &&
															experience?.fullPriceWithoutTax.toFixed(2)}
													</h5>
												</Col>
											</Row>
										</ListGroup.Item>
										{experience?.status !== 'Presale' && (
											<>
												<ListGroup.Item
													className='py-0'
													style={{ backgroundColor: '#F3F3F3' }}
												>
													{experience?.depositOption ? (
														<Row>
															<Col className='py-2' lg={6}>
																<h5 className='m-0'>Deposit Price:</h5>
															</Col>
															<Col className='py-2' lg={6}>
																<h5 className='m-0'>
																	$
																	{experience?.depositPrice &&
																		experience?.depositPrice.toFixed(2)}
																</h5>
															</Col>
														</Row>
													) : (
														<Row>
															<Col className='py-2' lg={6}>
																<h5 className='m-0'>Payment Deadline:</h5>
															</Col>
															<Col className='py-2' lg={6}>
																<h5 className='m-0'>
																	{displayExperienceDate(
																		experience?.finalPaymentDeadline,
																		'MMMM d'
																	)}
																</h5>
															</Col>
														</Row>
													)}
												</ListGroup.Item>
												<ListGroup.Item style={{ backgroundColor: '#F3F3F3' }}>
													<Button
														onClick={addToFlowHandler}
														className='btn-block'
														type='button'
														disabled={!enableButton}
													>
														{buttonLabel}
													</Button>
												</ListGroup.Item>
											</>
										)}

										{experience?.status === 'Presale' && (
											<ListGroup.Item style={{ backgroundColor: '#F3F3F3' }}>
												<Row>
													<Col md={7}>
														<h5 className='m-0'>Sign Up for Email Updates</h5>
													</Col>
												</Row>
												<Form className='py-2' onSubmit={addLeadHandler}>
													<Form.Group>
														<Form.Control
															style={{
																backgroundColor: '#fff',
															}}
															type='name'
															placeholder='Enter email for updates'
															value={leadEmail}
															onChange={(e) => setLeadEmail(e.target.value)}
														></Form.Control>
													</Form.Group>
													<Button
														type='submit'
														variant='primary'
														className='btn-block'
													>
														Sign up for updates
													</Button>
												</Form>
											</ListGroup.Item>
										)}
									</ListGroup>
								</Card>
							</Col>
						</Row>

						<Row>
							<Col md={12}>
								<ListGroup variant='flush'>
									<ListGroup.Item>
										<h2>Trip Overview</h2>
										{experience?.description}
									</ListGroup.Item>
								</ListGroup>
							</Col>
						</Row>

						<Gallery title='Itinerary' type='itinerary' />

						<Row>
							<Col>
								<ListGroup variant='flush'>
									<ListGroup.Item>
										<h2 className='mb-5'>Your Accommodations</h2>
										<Row>
											<Col md={4}>
												<div className='cards__item__pic-wrap-no-dc'>
													<img
														className='cards__item__img'
														alt='Travel Pic'
														src={experience?.accImage}
													/>
												</div>
											</Col>
											<Col md={8}>
												<h2>{experience?.accTitle}</h2>
												{experience?.accDescription}
											</Col>
										</Row>
									</ListGroup.Item>
								</ListGroup>
							</Col>
						</Row>

						<Gallery
							title={experience?.locationName ?? 'Things to do!'}
							type='destination'
						/>
						<ListGroup variant='flush' className='mt-3 mb-5'>
							<Row>
								{enableButton && (
									<Col
										sm={12}
										lg={travelers && userOrderId ? 6 : 12}
										className='my-2'
									>
										<Button
											onClick={addToFlowHandler}
											type='button'
											disabled={!enableButton}
											className='btn-block'
										>
											{buttonLabel}
										</Button>
									</Col>
								)}
								{travelers && userOrderId ? (
									<Col sm={12} lg={enableButton ? 6 : 12} className='my-2'>
										<Button
											onClick={handleShowModal}
											type='button'
											className='btn-block'
											variant='primary'
										>
											Show registered travelers
										</Button>
									</Col>
								) : (
									''
								)}
							</Row>
							<ListGroup.Item></ListGroup.Item>
						</ListGroup>
					</Container>
				</>
			)}
			<Modal show={showModal} onHide={handleCloseModal}>
				<Modal.Header closeButton>
					<Modal.Title>Travelers on {experience?.name}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{travelers?.length !== 0 ? (
						travelers?.map((order) => (
							<p key={order?._id}>{order?.user?.name}</p>
						))
					) : (
						<p>Be the first person to register for this experience!</p>
					)}
				</Modal.Body>
				<Modal.Footer>
					<Button variant='secondary' onClick={handleCloseModal}>
						Close
					</Button>
				</Modal.Footer>
			</Modal>

			<Modal show={showPresaleModal} onHide={handleClosePresaleModal}>
				<Modal.Header closeButton>
					<Modal.Title>Success!</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<p>Trip updates coming your way soon.</p>
				</Modal.Body>
				<Modal.Footer>
					<Button variant='secondary' onClick={handleClosePresaleModal}>
						Close
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	)
}

export default ExperienceScreen
