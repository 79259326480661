export const ORG_LIST_REQUEST = 'ORG_LIST_REQUEST'
export const ORG_LIST_SUCCESS = 'ORG_LIST_SUCCESS'
export const ORG_LIST_FAIL = 'ORG_LIST_FAIL'

export const ORG_DETAILS_REQUEST = 'ORG_DETAILS_REQUEST'
export const ORG_DETAILS_SUCCESS = 'ORG_DETAILS_SUCCESS'
export const ORG_DETAILS_FAIL = 'ORG_DETAILS_FAIL'
export const ORG_DETAILS_RESET = 'ORG_DETAILS_RESET'

// export const ORG_DELETE_REQUEST = 'ORG_DELETE_REQUEST'
// export const ORG_DELETE_SUCCESS = 'ORG_DELETE_SUCCESS'
// export const ORG_DELETE_FAIL = 'ORG_DELETE_FAIL'

// export const ORG_CREATE_REQUEST = 'ORG_CREATE_REQUEST'
// export const ORG_CREATE_SUCCESS = 'ORG_CREATE_SUCCESS'
// export const ORG_CREATE_FAIL = 'ORG_CREATE_FAIL'
// export const ORG_CREATE_RESET = 'ORG_CREATE_RESET'

// export const ORG_UPDATE_REQUEST = 'ORG_UPDATE_REQUEST'
// export const ORG_UPDATE_SUCCESS = 'ORG_UPDATE_SUCCESS'
// export const ORG_UPDATE_FAIL = 'ORG_UPDATE_FAIL'
// export const ORG_UPDATE_RESET = 'ORG_UPDATE_RESET'

// export const EXPERIENCE_CREATE_ITINERARYITEM_REQUEST =
// 	'EXPERIENCE_CREATE_ITINERARYITEM_REQUEST'
// export const EXPERIENCE_CREATE_ITINERARYITEM_SUCCESS =
// 	'EXPERIENCE_CREATE_ITINERARYITEM_SUCCESS'
// export const EXPERIENCE_CREATE_ITINERARYITEM_FAIL =
// 	'EXPERIENCE_CREATE_ITINERARYITEM_FAIL'
// export const EXPERIENCE_CREATE_ITINERARYITEM_RESET =
// 	'EXPERIENCE_CREATE_ITINERARYITEM_RESET'

// export const EXPERIENCE_CREATE_TRIPOPTION_REQUEST =
// 	'EXPERIENCE_CREATE_TRIPOPTION_REQUEST'
// export const EXPERIENCE_CREATE_TRIPOPTION_SUCCESS =
// 	'EXPERIENCE_CREATE_TRIPOPTION_SUCCESS'
// export const EXPERIENCE_CREATE_TRIPOPTION_FAIL =
// 	'EXPERIENCE_CREATE_TRIPOPTION_FAIL'
// export const EXPERIENCE_CREATE_TRIPOPTION_RESET =
// 	'EXPERIENCE_CREATE_TRIPOPTION_RESET'
