import axios from 'axios'
import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Form, Button, Container } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { DateTime } from 'luxon'
import Message from '../components/Message'
import Loader from '../components/Loader'
import FormContainer from '../components/FormContainer'
import {
	getExperienceDetails,
	updateExperience,
} from '../services/actions/experienceActions'
import { pstDate } from '../services/utils/dateFormats'
import {
	EXPERIENCE_UPDATE_RESET,
	EXPERIENCE_DETAILS_RESET,
} from '../services/constants/experienceConstants'
import DetailHeader from '../components/DetailHeader'

const ExperienceEditDetailsScreen = ({ match, history }) => {
	const experienceId = match.params.id
	const [uploading, setUploading] = useState(false)

	const [status, setStatus] = useState('Automatic')
	const [buttonOverrideText, setButtonOverrideText] = useState('')
	const [forceAllowPaymentOption, setForceAllowPaymentOption] = useState(false)
	const [name, setName] = useState('')
	const [locationName, setLocationName] = useState('')
	const [description, setDescription] = useState('')
	const [experienceStartDate, setExperienceStartDate] = useState('')
	const [experienceEndDate, setExperienceEndDate] = useState('')
	const [arrivalNotes, setArrivalNotes] = useState('')
	const [departureNotes, setDepartureNotes] = useState('')
	const [depositPrice, setDepositPrice] = useState(0)
	const [fullPriceWithoutTax, setFullPriceWithoutTax] = useState(0)
	const [taxRate, setTaxRate] = useState(0)
	const [fullPriceWithTax, setFullPriceWithTax] = useState(0)
	const [finalPaymentDeadline, setFinalPaymentDeadline] = useState('')
	const [depositPaymentDeadline, setDepositPaymentDeadline] = useState('')
	const [onSaleDate, setOnSaleDate] = useState('')
	const [capacity, setCapacity] = useState(0)
	const [image, setImage] = useState('')
	const [brand, setBrand] = useState('')
	const [type, setType] = useState('')
	const [tcs, setTcs] = useState('')
	const [depositOption, setDepositOption] = useState(true)
	const [sizingOption, setSizingOption] = useState(true)

	const dispatch = useDispatch()

	const userLogin = useSelector((state) => state.userLogin)
	const { userInfo: isAdmin } = userLogin

	const experienceDetails = useSelector((state) => state.experienceDetails)
	const { loading, error, experience } = experienceDetails

	const experienceUpdate = useSelector((state) => state.experienceUpdate)
	const {
		loading: loadingUpdate,
		error: errorUpdate,
		success: successUpdate,
	} = experienceUpdate

	useEffect(() => {
		if (!isAdmin) {
			history.push('/')
		} else if (successUpdate) {
			dispatch({ type: EXPERIENCE_UPDATE_RESET })
			dispatch({ type: EXPERIENCE_DETAILS_RESET })
			history.push('/admin/experienceList')
		} else {
			if (!experience.name || experience._id !== experienceId) {
				dispatch(getExperienceDetails(experienceId))
			} else {
				setStatus(experience.status)
				setName(experience.name)
				setButtonOverrideText(experience.buttonOverrideText)
				setForceAllowPaymentOption(experience.forceAllowPaymentOption)
				setLocationName(experience.locationName)
				setDescription(experience.description)
				setExperienceStartDate(experience.experienceStartDate)
				setExperienceEndDate(experience.experienceEndDate)
				setDepositOption(experience.depositOption)
				setDepositPrice(experience.depositPrice)
				setFullPriceWithoutTax(experience.fullPriceWithoutTax)
				setTaxRate(experience.taxRate)
				setFullPriceWithTax(experience.fullPriceWithTax)
				setCapacity(experience.capacity)
				setImage(experience.image)
				setBrand(experience.brand)
				setType(experience.type)
				setArrivalNotes(experience.arrivalNotes)
				setDepartureNotes(experience.departureNotes)
				setFinalPaymentDeadline(experience.finalPaymentDeadline)
				setDepositPaymentDeadline(experience.depositPaymentDeadline)
				setOnSaleDate(experience.onSaleDate)
				setSizingOption(experience.sizingOption)
				setTcs(experience.termsAndConditionsNote)
			}
		}
	}, [experience, experienceId, dispatch, history, successUpdate, isAdmin])

	const uploadFileHandler = async (e) => {
		const file = e.target.files[0]
		const formData = new FormData()
		formData.append('image', file)
		setUploading(true)

		try {
			const config = {
				headers: {
					'Content-type': 'multipart/form/data',
				},
			}
			const { data } = await axios.post('/api/upload', formData, config)

			setImage(data)
			setUploading(false)
		} catch (error) {
			console.error.apply(error)
			setUploading(false)
		}
	}

	const submitHandler = (e) => {
		e.preventDefault()
		dispatch(
			updateExperience({
				_id: experienceId,
				status,
				name,
				buttonOverrideText,
				forceAllowPaymentOption,
				locationName,
				description,
				experienceStartDate,
				experienceEndDate,
				depositOption,
				depositPrice,
				fullPriceWithoutTax,
				taxRate,
				fullPriceWithTax,
				capacity,
				image,
				brand,
				type,
				arrivalNotes,
				departureNotes,
				finalPaymentDeadline,
				depositPaymentDeadline,
				onSaleDate,
				sizingOption,
				tcs,
			})
		)
	}

	const displayUtcDateInPst = (date) => {
		const dateAsDate = new Date(date).toISOString()
		const pstDate = DateTime.fromISO(dateAsDate)
			.setZone('America/Los_Angeles')
			.toISO()
		return pstDate
	}

	const endOfDayOffset = (date) => {
		const dateAsDate = new Date(date).toISOString().replace('Z', '')
		const endOfDay = DateTime.fromISO(dateAsDate, {
			zone: 'America/Los_Angeles',
		})
			.endOf('day')
			.toUTC()
			.toISO()
		return endOfDay
	}

	return (
		<>
			<DetailHeader />
			<Container>
				<Link to='/admin/experiencelist' className='btn btn-light my-3'>
					Go Back
				</Link>
				<FormContainer>
					<h1>Edit Experience Details</h1>
					{loadingUpdate && <Loader />}
					{errorUpdate && <Message variant='danger'>{errorUpdate}</Message>}
					{loading ? (
						<Loader />
					) : error ? (
						<Message variant='danger'>{error}</Message>
					) : (
						<Form onSubmit={submitHandler}>
							<h2>Status</h2>
							<Form.Group controlId='status'>
								<Form.Label>Experience Status</Form.Label>
								<Form.Control
									as='select'
									type='text'
									value={status}
									placeholder='Select a status...'
									required
									onChange={(e) => setStatus(e.target.value)}
								>
									<option>Automatic</option>
									<option>Presale</option>
									<option>Open</option>
									<option>Closed</option>
								</Form.Control>
							</Form.Group>

							{status === 'Closed' && (
								<>
									<Form.Group controlId='buttonOverrideText'>
										<Form.Label>
											Override "Not Available" button text
										</Form.Label>
										<Form.Control
											type='name'
											placeholder='E.g. "Sold Out"'
											value={buttonOverrideText}
											onChange={(e) => setButtonOverrideText(e.target.value)}
										></Form.Control>
									</Form.Group>

									<Form.Group controlId='allowPayment'>
										<Form.Check
											type='switch'
											id='allow-payment-switch'
											label='Allow Payment From Reserved Travelers (Until Deadline)?'
											onChange={(e) =>
												setForceAllowPaymentOption(e.target.checked)
											}
											checked={!!forceAllowPaymentOption}
										/>
									</Form.Group>
								</>
							)}

							<br />
							<h2>General</h2>
							<Form.Group controlId='name'>
								<Form.Label>Name</Form.Label>
								<Form.Control
									type='name'
									placeholder='Enter name'
									value={name}
									onChange={(e) => setName(e.target.value)}
								></Form.Control>
							</Form.Group>
							<Form.Group controlId='locationName'>
								<Form.Label>Location Name</Form.Label>
								<Form.Control
									type='text'
									placeholder='Enter location name'
									value={locationName}
									onChange={(e) => setLocationName(e.target.value)}
								></Form.Control>
							</Form.Group>
							<Form.Group controlId='capacity'>
								<Form.Label>Trip Capacity</Form.Label>
								<Form.Control
									type='number'
									placeholder='Enter current trip capacity'
									value={capacity}
									onChange={(e) => setCapacity(e.target.value)}
								></Form.Control>
							</Form.Group>
							<Form.Group controlId='description'>
								<Form.Label>Description</Form.Label>
								<Form.Control
									as='textarea'
									type='text'
									placeholder='Enter description'
									value={description}
									onChange={(e) => setDescription(e.target.value)}
									rows={5}
								></Form.Control>
							</Form.Group>
							<Form.Group controlId='image'>
								<Form.Label>Image</Form.Label>
								<Form.Control
									type='text'
									placeholder='Enter image url'
									value={image}
									onChange={(e) => setImage(e.target.value)}
								></Form.Control>
								<Form.File
									id='image-file'
									label='Choose File'
									custom
									onChange={uploadFileHandler}
								></Form.File>
								{uploading && <Loader />}
							</Form.Group>
							<Form.Group controlId='brand'>
								<Form.Label>Brand</Form.Label>
								<Form.Control
									type='text'
									placeholder='Enter brand'
									value={brand}
									onChange={(e) => setBrand(e.target.value)}
								></Form.Control>
							</Form.Group>
							<Form.Group controlId='type'>
								<Form.Label>Type</Form.Label>
								<Form.Control
									type='text'
									placeholder='Enter type of trip'
									value={type}
									onChange={(e) => setType(e.target.value)}
								></Form.Control>
							</Form.Group>
							<br />
							<h2>Dates</h2>
							<h5>Experience Dates</h5>
							<Form.Group controlId='experienceStartDate'>
								<Form.Label>Experience Start Date</Form.Label>
								<Form.Control
									type='date'
									placeholder='Enter experience start date'
									value={
										experienceStartDate &&
										displayUtcDateInPst(experienceStartDate).substring(0, 10)
									}
									onChange={(e) =>
										setExperienceStartDate(pstDate(e.target.value))
									}
								></Form.Control>
							</Form.Group>
							<Form.Group controlId='experienceEndDate'>
								<Form.Label>Experience End Date</Form.Label>
								<Form.Control
									type='date'
									placeholder='Enter experience end date'
									value={
										experienceEndDate
											? displayUtcDateInPst(experienceEndDate).substring(0, 10)
											: ''
									}
									onChange={(e) =>
										setExperienceEndDate(pstDate(e.target.value))
									}
								></Form.Control>
							</Form.Group>
							<br />
							<h5>Payment Dates</h5>
							<Form.Group controlId='onSaleDate'>
								<Form.Label>On Sale Date</Form.Label>
								<Form.Control
									type='date'
									placeholder='Enter On Sale Date'
									value={
										onSaleDate
											? displayUtcDateInPst(onSaleDate).substring(0, 10)
											: ''
									}
									onChange={(e) => setOnSaleDate(pstDate(e.target.value))}
								></Form.Control>
							</Form.Group>
							<Form.Group controlId='depositOption'>
								<Form.Check
									type='switch'
									id='deposit-switch'
									label='Has option deposit option?'
									onChange={(e) => setDepositOption(e.target.checked)}
									checked={!!depositOption}
								/>
							</Form.Group>
							<Form.Group controlId='depositPaymentDeadline'>
								<Form.Label>Deposit Payment Deadline</Form.Label>
								<Form.Control
									type='date'
									placeholder='Enter deposit payment deadline date'
									value={
										depositOption && depositPaymentDeadline
											? displayUtcDateInPst(depositPaymentDeadline).substring(
													0,
													10
											  )
											: ''
									}
									onChange={(e) =>
										setDepositPaymentDeadline(endOfDayOffset(e.target.value))
									}
									disabled={!depositOption}
								></Form.Control>
							</Form.Group>
							<Form.Group controlId='finalPaymentDeadline'>
								<Form.Label>Final Payment Deadline</Form.Label>
								<Form.Control
									type='date'
									placeholder='Enter final payment deadline date'
									value={
										finalPaymentDeadline
											? displayUtcDateInPst(finalPaymentDeadline).substring(
													0,
													10
											  )
											: ''
									}
									onChange={(e) =>
										setFinalPaymentDeadline(endOfDayOffset(e.target.value))
									}
								></Form.Control>
							</Form.Group>
							<br />
							<h2>Directions</h2>
							<Form.Group controlId='arrivalNotes'>
								<Form.Label>Arrival Notes</Form.Label>
								<Form.Control
									as='textarea'
									type='text'
									placeholder='Enter arrival notes'
									value={arrivalNotes}
									onChange={(e) => setArrivalNotes(e.target.value)}
									rows={2}
								></Form.Control>
							</Form.Group>
							<Form.Group controlId='departureNotes'>
								<Form.Label>Departure Notes</Form.Label>
								<Form.Control
									as='textarea'
									type='text'
									placeholder='Enter departure notes'
									value={departureNotes}
									onChange={(e) => setDepartureNotes(e.target.value)}
									rows={2}
								></Form.Control>
							</Form.Group>
							<br />
							<h2>Pricing</h2>
							<Form.Group controlId='depositPrice'>
								<Form.Label>Deposit Amount</Form.Label>
								<Form.Control
									type='number'
									placeholder='Enter deposit amount'
									value={depositPrice}
									onChange={(e) => {
										setDepositPrice(e.target.value)
									}}
									disabled={!depositOption}
								></Form.Control>
							</Form.Group>
							<Form.Group controlId='priceWithoutTax'>
								<Form.Label>Total Individual Price (without tax)</Form.Label>
								<Form.Control
									type='number'
									placeholder='Enter trip price'
									value={fullPriceWithoutTax}
									onChange={(e) => {
										setFullPriceWithoutTax(e.target.value)
										setFullPriceWithTax(
											Number(e.target.value * (1 + parseFloat(taxRate)))
										)
									}}
								></Form.Control>
							</Form.Group>
							<Form.Group controlId='tax'>
								<Form.Label>Tax Rate (%)</Form.Label>
								<Form.Control
									type='number'
									placeholder='Enter tax rate'
									value={taxRate}
									onChange={(e) => {
										setTaxRate(e.target.value)
										setFullPriceWithTax(
											Number(
												(
													fullPriceWithoutTax *
													(1 + parseFloat(e.target.value))
												).toFixed(2)
											)
										)
									}}
								></Form.Control>
							</Form.Group>
							<Form.Group controlId='priceWithTax'>
								<Form.Label>Total Individual Price (with taxes)</Form.Label>
								<Form.Control
									type='number'
									value={Number(fullPriceWithTax.toFixed(2))}
									disabled
								></Form.Control>
							</Form.Group>
							<br />
							<h2>Sizing</h2>
							<Form.Group controlId='sizingOption'>
								<Form.Check
									type='switch'
									id='sizingSwitch'
									label='Requires sizing information?'
									onChange={(e) => setSizingOption(e.target.checked)}
									checked={!!sizingOption}
								/>
							</Form.Group>
							<br />
							<h2>Terms and Conditions</h2>
							<Form.Group controlId='tcs'>
								<Form.Label>Terms and Conditions</Form.Label>
								<Form.Control
									as='textarea'
									type='text'
									placeholder='Enter the Terms and Conditions'
									value={tcs}
									onChange={(e) => setTcs(e.target.value)}
									rows={5}
								></Form.Control>
							</Form.Group>
							<Button type='submit' variant='primary'>
								Update
							</Button>
						</Form>
					)}
				</FormContainer>
			</Container>
		</>
	)
}

export default ExperienceEditDetailsScreen
