import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Form, Button, Row, Col, Container } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import Loader from '../components/Loader'
import FormContainer from '../components/FormContainer'
import { register } from '../services/actions/userActions'

const RegisterScreen = ({ location, history }) => {
	const [name, setName] = useState('')
	const [email, setEmail] = useState('')
	const [password, setPassword] = useState('')
	const [confirmPassword, setConfirmPassword] = useState('')

	const [message, setMessage] = useState(null)
	const [saveError, setSaveError] = useState(null)

	const dispatch = useDispatch()

	const userRegister = useSelector((state) => state.userRegister)
	const { loading, error, userInfo } = userRegister

	useEffect(() => {
		setSaveError(error)
	}, [history, userRegister])

	const redirect = location.search ? location.search.split('=')[1] : '/'

	useEffect(() => {
		if (userInfo) {
			history.push(redirect)
		}
	}, [history, userInfo, redirect])

	const validatePassword = (password, confirmPassword) => {
		if (password !== confirmPassword) {
			const error = 'Passwords do not match'
			return error
		}
		if (password.length < 8) {
			const error = 'Password must be at least 8 characters long.'
			return error
		}
		if (!/[A-Z]/.test(password)) {
			const error = 'Password must include at least one uppercase letter.'
			return error
		}
		if (!/[a-z]/.test(password)) {
			const error = 'Password must include at least one lowercase letter.'
			return error
		}
		if (!/[!@#$%^&*(),.?":{}|<>]/.test(password)) {
			const error = 'Password must include at least one special character.'
			return error
		}
		if (/\s/.test(password)) {
			const error = 'Password must not contain spaces.'
			return error
		}
		const error = false
		return error
	}

	const submitHandler = (e) => {
		e.preventDefault()
		setMessage('')
		setSaveError('')
		const passwordErrors = validatePassword(password, confirmPassword)
		if (passwordErrors) {
			setMessage(passwordErrors)
			return
		} else {
			dispatch(register(name, email, password))
		}
	}

	return (
		<Container className='my-4'>
			<FormContainer>
				<h1>Sign Up</h1>
				{message && <Message variant='danger'>{message}</Message>}
				{saveError && <Message variant='danger'>{saveError}</Message>}
				{loading && <Loader />}
				<Form onSubmit={submitHandler}>
					<Form.Group controlId='fullName'>
						<Form.Label>Full Name</Form.Label>
						<Form.Control
							type='full name'
							placeholder='Enter full name'
							value={name}
							onChange={(e) => setName(e.target.value)}
							required
						></Form.Control>
					</Form.Group>

					<Form.Group controlId='email'>
						<Form.Label>Email Address</Form.Label>
						<Form.Control
							type='email'
							placeholder='Enter email'
							value={email}
							onChange={(e) => setEmail(e.target.value)}
							required
						></Form.Control>
					</Form.Group>

					<Form.Group controlId='password'>
						<Form.Label>Password</Form.Label>
						<Form.Control
							type='password'
							placeholder='Enter password'
							value={password}
							onChange={(e) => setPassword(e.target.value)}
							required
							autoComplete='new-password'
						></Form.Control>
					</Form.Group>

					<Form.Group controlId='confirmPassword'>
						<Form.Label>Confirm Password</Form.Label>
						<Form.Control
							type='password'
							placeholder='Confirm password'
							value={confirmPassword}
							onChange={(e) => setConfirmPassword(e.target.value)}
							required
							autoComplete='new-password'
						></Form.Control>
					</Form.Group>

					<Button type='submit' variant='primary'>
						Register
					</Button>
				</Form>

				<Row className='py-3'>
					<Col>
						Have an account?{' '}
						<Link to={redirect ? `/login?redirect=${redirect}` : '/login'}>
							Log in
						</Link>
					</Col>
				</Row>
			</FormContainer>
		</Container>
	)
}

export default RegisterScreen
