import axios from 'axios'
import {
	FLOW_ADD_ITEM,
	FLOW_REMOVE_ITEM,
	FLOW_SAVE_PAYMENT_METHOD,
	FLOW_SAVE_ORDER_INFO,
} from '../constants/flowConstants'

export const addToFlow = (id) => async (dispatch, getState) => {
	const { data } = await axios.get(`/api/experiences/${id}`)
	dispatch({
		type: FLOW_ADD_ITEM,
		payload: {
			experience: data._id,
			status: data.status,
			name: data.name,
			image: data.image,
			fullPriceWithTax: data.fullPriceWithTax,
			depositPrice: data.depositPrice,
			finalPaymentDueDate: data.finalPaymentDeadline,
			depositPaymentDueDate: data.depositPaymentDeadline,
			onSaleDate: data.onSaleDate,
			capacity: data.capacity,
			arrivalNotes: data.arrivalNotes,
			departureNotes: data.departureNotes,
			locationName: data.locationName,
			experienceStartDate: data.experienceStartDate,
			experienceEndDate: data.experienceEndDate,
			itinerary: data.itinerary,
			tripOption: data.tripOption,
			accTitle: data.accTitle,
			accDescription: data.accDescription,
			depositOption: data.depositOption,
			termsAndConditionsNote: data.termsAndConditionsNote,
			taxRate: data.taxRate,
			sizingOption: data.sizingOption,
		},
	})
	dispatch({
		type: FLOW_SAVE_ORDER_INFO,
		payload: {
			experiencePrice: data.fullPriceWithoutTax,
			experienceTaxes: parseFloat(
				(data.fullPriceWithoutTax * data.taxRate).toFixed(2)
			),
			addOnsPrice: 0,
			addOnsTaxes: 0,
			totalProductAmount: data.fullPriceWithTax,
			totalTaxesAmount: parseFloat(
				(data.fullPriceWithoutTax * data.taxRate).toFixed(2)
			),
			totalOrderAmount: data.fullPriceWithTax,
			depositPrice: data.depositPrice,
			finalPaymentDueDate: data.finalPaymentDeadline,
			addOns: [],
		},
	})

	localStorage.setItem('flowItem', JSON.stringify(getState().flow.flowItem))
	localStorage.setItem('orderInfo', JSON.stringify(getState().flow.orderInfo))
}

export const removeFromFlow = (id) => (dispatch, getState) => {
	dispatch({
		type: FLOW_REMOVE_ITEM,
		payload: id,
	})

	localStorage.setItem('flowItem', JSON.stringify(getState().flow.flowItem))
}

export const savePaymentStructure = (data) => (dispatch) => {
	dispatch({
		type: FLOW_SAVE_PAYMENT_METHOD,
		payload: data,
	})
	localStorage.setItem('paymentStructure', JSON.stringify(data))
}

export const saveOrderInfo = (data) => (dispatch, getState) => {
	dispatch({
		type: FLOW_SAVE_ORDER_INFO,
		payload: data,
	})
	localStorage.setItem('orderInfo', JSON.stringify(getState().flow.orderInfo))
}
