import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Button, Form } from 'react-bootstrap'
import './MembershipOptionCard.css'

function MembershipOptionCard({ program, org, handleSetMembershipFlow }) {
	const membershipProgramSelected = useSelector(
		(state) => state.membershipFlow.flowMembershipProgram
	)

	const [selectionStatus, setSelectionStatus] = useState(
		membershipProgramSelected === program._id ? true : false
	)

	const [buttonTitle, setButtonTitle] = useState(
		selectionStatus ? 'Remove' : 'Select Membership'
	)

	const submitHandler = (program) => {
		if (selectionStatus) {
			handleSetMembershipFlow('', '')
			setSelectionStatus(!selectionStatus)
			setButtonTitle('Select Membership')
		} else {
			handleSetMembershipFlow(program._id, org)
			setSelectionStatus(!selectionStatus)
			setButtonTitle('Remove')
		}
	}

	return (
		<>
			<div
				style={{
					boxShadow: '0px 0px 15px  #f5f5f5',
					margin: '1rem 0 1rem 0',
				}}
			>
				<li className='option_cards__item'>
					<div className='option_cards__item__link'>
						<figure
							className='option_cards__item__pic-wrap'
							data-category={`$${program?.fullPriceWithoutTax?.toFixed(2)}`}
						>
							<img
								className='option_cards__item__img'
								alt={program.name}
								src={program.image}
							/>
						</figure>
						<div className='option_cards__item__info py-2'>
							<h5 className='option_cards__item__text mb-2'>{program.name}</h5>
						</div>
						<p className='m-2'>{program.description}</p>
					</div>
				</li>

				<Button
					onClick={() => submitHandler(program)}
					className='btn-block'
					type='button'
				>
					{buttonTitle}
				</Button>
			</div>
		</>
	)
}

export default MembershipOptionCard
