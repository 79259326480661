import React from 'react'
import { useSelector } from 'react-redux'
import { LinkContainer } from 'react-router-bootstrap'
import { Navbar, Nav, Container } from 'react-bootstrap'

const OrgAdminNav = () => {
	const userLogin = useSelector((state) => state.userLogin)
	const { userInfo } = userLogin

	const orgDetails = useSelector((state) => state.orgDetails)
	const { org } = orgDetails

	const orgSlug = org.slug

	return (
		<>
			{userInfo && userInfo.isAdmin && false && (
				<Navbar
					style={{ backgroundColor: org?.primaryColor ?? '#cccccc' }}
					variant='dark'
					expand='sm'
					collapseOnSelect
					className='navbar'
				>
					<Container>
						<LinkContainer
							to={`/admin/experience/${orgSlug}/details`}
							style={{ color: '#000', fontSize: '1rem' }}
						>
							<Navbar.Brand>{org.name}</Navbar.Brand>
						</LinkContainer>
						<Navbar.Toggle aria-controls='basic-navbar-nav' />
						<Navbar.Collapse id='basic-navbar-nav'>
							<Nav className='ml-auto'>
								<LinkContainer to={`/admin/experience/${orgSlug}/details`}>
									<Nav.Item>Details</Nav.Item>
								</LinkContainer>
								<LinkContainer to={`/admin/experience/${orgSlug}/itinerary`}>
									<Nav.Item>Itinerary</Nav.Item>
								</LinkContainer>
								<LinkContainer
									to={`/admin/experience/${orgSlug}/accommodations`}
								>
									<Nav.Item>Accommodation</Nav.Item>
								</LinkContainer>
								<LinkContainer to={`/admin/experience/${orgSlug}/destination`}>
									<Nav.Item>Destination</Nav.Item>
								</LinkContainer>
								<LinkContainer to={`/admin/experience/${orgSlug}/tripoption`}>
									<Nav.Item>Trip Options</Nav.Item>
								</LinkContainer>
								<LinkContainer to={`/admin/experience/${orgSlug}/orders`}>
									<Nav.Item>Orders</Nav.Item>
								</LinkContainer>
								<LinkContainer to={`/admin/experience/${orgSlug}/leads`}>
									<Nav.Item>Leads</Nav.Item>
								</LinkContainer>
							</Nav>
						</Navbar.Collapse>
					</Container>
				</Navbar>
			)}
		</>
	)
}

export default OrgAdminNav
