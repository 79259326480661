import {
	FLOW_ADD_ITEM,
	FLOW_SAVE_PAYMENT_METHOD,
	FLOW_SAVE_ORDER_INFO,
} from '../constants/flowConstants'

export const flowReducer = (state = { flowItem: {} }, action) => {
	switch (action.type) {
		case FLOW_ADD_ITEM:
			return {
				...state,
				flowItem: action.payload,
			}

		case FLOW_SAVE_PAYMENT_METHOD:
			return {
				...state,
				paymentStructure: action.payload,
			}

		case FLOW_SAVE_ORDER_INFO:
			return {
				...state,
				orderInfo: action.payload,
			}

		default:
			return state
	}
}
