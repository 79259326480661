import React, { useEffect, useCallback } from 'react'
import { withRouter } from 'react-router-dom'
import { useDispatch } from 'react-redux'

const parseJwt = (token) => {
	try {
		return JSON.parse(atob(token.split('.')[1]))
	} catch (e) {
		return null
	}
}
const AuthVerify = (props) => {
	const dispatch = useDispatch()

	const verifyToken = useCallback(() => {
		const user = JSON.parse(localStorage.getItem('userInfo'))
		if (user) {
			const decodedJwt = parseJwt(user.token)
			if (decodedJwt.exp * 1000 < Date.now()) {
				dispatch(props.logout())
				props.history.push('/login')
			}
		}
	}, [props, dispatch])

	useEffect(() => {
		verifyToken()
	}, [verifyToken])

	props.history.listen(() => {
		verifyToken()
	})
	return <></>
}
export default withRouter(AuthVerify)
