import React from 'react'
import { Nav } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
import { useSelector } from 'react-redux'

const MembershipFlowSteps = ({
	org,
	selectedMembershipProgramId,
	membershipOptions,
	review,
	memberInfo,
}) => {
	return (
		<Nav className='justify-content-center mb-4'>
			<Nav.Item className='mr-2 ml-2'>
				{membershipOptions ? (
					<LinkContainer to={`/org/${org.slug}/membership-options`}>
						<Nav.Link>Options</Nav.Link>
					</LinkContainer>
				) : (
					<Nav.Link disabled>Options</Nav.Link>
				)}
			</Nav.Item>

			<Nav.Item className='mr-2 ml-2'>
				{memberInfo ? (
					<LinkContainer to={`/org/${org.slug}/member-info`}>
						<Nav.Link>Member Info</Nav.Link>
					</LinkContainer>
				) : (
					<Nav.Link disabled>Member Info</Nav.Link>
				)}
			</Nav.Item>

			<Nav.Item className='mr-2 ml-2'>
				{review ? (
					<LinkContainer
						to={`/org/${org.slug}/${selectedMembershipProgramId}/review-membership`}
					>
						<Nav.Link>Review</Nav.Link>
					</LinkContainer>
				) : (
					<Nav.Link disabled>Review</Nav.Link>
				)}
			</Nav.Item>

			<Nav.Item className='mr-2 ml-2'>
				<Nav.Link disabled>Payment</Nav.Link>
			</Nav.Item>
		</Nav>
	)
}

export default MembershipFlowSteps
