import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Row, ListGroup, Button, Container, Form } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
import Message from '../components/Message'
import CheckoutSteps from '../components/CheckoutSteps'
import { addToFlow } from '../services/actions/flowActions'

const ReviewScreen = ({ match, history }) => {
	const experienceId = match.params.id
	const [agreedToTcs, setAgreedToTcs] = useState(false)
	const [tcsWarning, setTcsWarning] = useState(false)

	const dispatch = useDispatch()

	const flow = useSelector((state) => state.flow)
	const { flowItem } = flow

	//Date Logic
	const currentYear = new Date().getFullYear()

	const startYear = new Date(flowItem?.experienceStartDate).getFullYear()

	const startMonth =
		flowItem &&
		new Date(flowItem?.experienceStartDate).toLocaleString('default', {
			month: 'short',
		})

	const startDay = new Date(flowItem?.experienceStartDate).getDate()

	const endYear = new Date(flowItem?.experienceEndDate).getFullYear()

	const endMonth =
		flowItem &&
		new Date(flowItem?.experienceEndDate).toLocaleString('default', {
			month: 'short',
		})

	const endDay = flowItem && new Date(flowItem?.experienceEndDate).getDate()

	const startDate =
		flowItem &&
		startMonth +
			' ' +
			startDay +
			(startYear !== endYear ? ', ' + startYear : '')

	const endDate =
		(startMonth === endMonth ? endDay : endMonth + ' ' + endDay) +
		(endYear !== startYear || endYear !== currentYear ? ', ' + endYear : '')

	const fullDate = flowItem && startDate + ' - ' + endDate
	//End Date Logic

	useEffect(() => {
		if (experienceId) {
			dispatch(addToFlow(experienceId))
		}
	}, [dispatch, experienceId])

	const checkoutHandler = () => {
		agreedToTcs || !flowItem?.termsAndConditionsNote
			? history.push('/login?redirect=travelerinfo')
			: setTcsWarning(true)
	}

	return (
		<>
			{Object.keys(flowItem).length === 0 ? (
				<Container>
					You have no active bookings <Link to='/'>Go Back</Link>
				</Container>
			) : (
				<div className='my-4'>
					<Container>
						<Container
							className='my-4'
							style={{
								boxShadow: '0 0 10px #b0b0b0',
							}}
						>
							<ListGroup variant='flush'>
								<ListGroup.Item className='p-2 mt-4'>
									<h1 className='text-center'>{flowItem.name}</h1>
									<h5 className='text-center px-5'>
										<span className='d-none d-md-block'>
											{flowItem.locationName} | {fullDate}
										</span>

										<span className='d-md-none'>
											{flowItem.locationName}
											<br />
											{fullDate}
										</span>
									</h5>
								</ListGroup.Item>
							</ListGroup>

							<Row
								className='justify-content-center'
								style={{ backgroundColor: '#F3F3F3' }}
							>
								<CheckoutSteps review />
							</Row>
						</Container>
					</Container>

					<Container>
						<div>
							<h1>Review Experience Details</h1>
							<h5>
								<em>Please look carefully over this information</em>
							</h5>
						</div>
						<Row className='mt-3 p-2' style={{ borderLeft: '5px solid black' }}>
							<h5>The Spot:</h5>
							{/* Remove after Kyle and Katie's party */}
							{flowItem.accTitle}
							{'.'} {flowItem.accDescription}
						</Row>
						<Row className='mt-3 p-2' style={{ borderLeft: '5px solid grey' }}>
							<h5>Arrival:</h5>
							<p>{flowItem.arrivalNotes}</p>
						</Row>
						{flowItem.itinerary
							? flowItem.itinerary.map((item) => (
									<Row
										key={item._id}
										className='mt-3 p-2'
										style={{ borderLeft: '5px solid #F3F3F3' }}
									>
										<h5>{item.dayName}:</h5> {item.description}
									</Row>
							  ))
							: ''}

						<Row className='mt-3 p-2' style={{ borderLeft: '5px solid grey' }}>
							<h5>Departure:</h5> {flowItem.departureNotes}
						</Row>
						{flowItem?.termsAndConditionsNote && (
							<Row
								className='mt-3 p-2'
								style={{ borderLeft: '5px solid black' }}
							>
								<h5>Terms and Conditions:</h5>
								<div style={{ maxHeight: '115px', overflow: 'scroll' }}>
									{flowItem.termsAndConditionsNote}
								</div>
							</Row>
						)}
						{flowItem?.termsAndConditionsNote && (
							<Row className='mt-3 p-2'>
								<Form.Group controlId='tcsCheckbox'>
									<Form.Check
										type='checkbox'
										label='I have read,
							understand, and agree to the Terms and Conditions'
										onChange={(e) => {
											setAgreedToTcs(e.target.checked)
											setTcsWarning(false)
										}}
									/>
								</Form.Group>
							</Row>
						)}
						{tcsWarning && (
							<Message variant='danger'>
								Please read and accept the Terms and Conditions
							</Message>
						)}
						<Row className='mt-3 p-2'>
							<LinkContainer to={`/experiences/${experienceId}`}>
								<Button className='mr-1 btn-secondary' type='button'>
									Back
								</Button>
							</LinkContainer>
							<Button className='' type='button' onClick={checkoutHandler}>
								Continue
							</Button>
						</Row>
					</Container>
				</div>
			)}
		</>
	)
}

export default ReviewScreen
