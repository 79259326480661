import axios from 'axios'
import {
	ORG_LIST_REQUEST,
	ORG_LIST_SUCCESS,
	ORG_LIST_FAIL,
	ORG_DETAILS_REQUEST,
	ORG_DETAILS_SUCCESS,
	ORG_DETAILS_FAIL,
	// EXPERIENCE_DELETE_REQUEST,
	// EXPERIENCE_DELETE_SUCCESS,
	// EXPERIENCE_DELETE_FAIL,
	// EXPERIENCE_CREATE_REQUEST,
	// EXPERIENCE_CREATE_SUCCESS,
	// EXPERIENCE_CREATE_FAIL,
	// EXPERIENCE_UPDATE_REQUEST,
	// EXPERIENCE_UPDATE_SUCCESS,
	// EXPERIENCE_UPDATE_FAIL,
	// EXPERIENCE_CREATE_ITINERARYITEM_REQUEST,
	// EXPERIENCE_CREATE_ITINERARYITEM_SUCCESS,
	// EXPERIENCE_CREATE_ITINERARYITEM_FAIL,
	// EXPERIENCE_CREATE_TRIPOPTION_REQUEST,
	// EXPERIENCE_CREATE_TRIPOPTION_SUCCESS,
	// EXPERIENCE_CREATE_TRIPOPTION_FAIL,
} from '../constants/orgConstants'
import { logout } from './userActions'

export const listOrgs =
	(keyword = '') =>
	async (dispatch) => {
		try {
			dispatch({
				type: ORG_LIST_REQUEST,
			})

			const { data } = await axios.get(`/api/orgs?keyword=${keyword}`)

			dispatch({
				type: ORG_LIST_SUCCESS,
				payload: data,
			})
		} catch (error) {
			dispatch({
				type: ORG_LIST_FAIL,
				payload:
					error.response && error.response.data.message
						? error.response.data.message
						: error.message,
			})
		}
	}

export const getOrgDetails = (slug) => async (dispatch) => {
	try {
		dispatch({
			type: ORG_DETAILS_REQUEST,
		})

		const { data } = await axios.get(`/api/orgs/${slug}`)

		dispatch({
			type: ORG_DETAILS_SUCCESS,
			payload: data,
		})
	} catch (error) {
		dispatch({
			type: ORG_DETAILS_FAIL,
			payload:
				error.response && error.response.data.message
					? error.response.data.message
					: error.message,
		})
	}
}

// export const deleteExperience = (id) => async (dispatch, getState) => {
// 	try {
// 		dispatch({
// 			type: EXPERIENCE_DELETE_REQUEST,
// 		})

// 		const {
// 			userLogin: { userInfo },
// 		} = getState()

// 		const config = {
// 			headers: {
// 				Authorization: `Bearer ${userInfo.token}`,
// 			},
// 		}

// 		await axios.delete(`/api/experiences/${id}`, config)

// 		dispatch({
// 			type: EXPERIENCE_DELETE_SUCCESS,
// 		})
// 	} catch (error) {
// 		dispatch({
// 			type: EXPERIENCE_DELETE_FAIL,
// 			payload:
// 				error.response && error.response.data.message
// 					? error.response.data.message
// 					: error.message,
// 		})
// 	}
// }

// // export const createExperience = () => async (dispatch, getState) => {
// // 	try {
// // 		dispatch({
// // 			type: EXPERIENCE_CREATE_REQUEST,
// // 		})

// // 		const {
// // 			userLogin: { userInfo },
// // 		} = getState()

// // 		const config = {
// // 			headers: {
// // 				Authorization: `Bearer ${userInfo.token}`,
// // 			},
// // 		}

// // 		const { data } = await axios.post(`/api/experiences`, {}, config)

// // 		dispatch({
// // 			type: EXPERIENCE_CREATE_SUCCESS,
// // 			payload: data,
// // 		})
// // 	} catch (error) {
// // 		dispatch({
// // 			type: EXPERIENCE_CREATE_FAIL,
// // 			payload:
// // 				error.response && error.response.data.message
// // 					? error.response.data.message
// // 					: error.message,
// // 		})
// // 	}
// // }

// // export const updateExperience = (experience) => async (dispatch, getState) => {
// // 	try {
// // 		dispatch({
// // 			type: EXPERIENCE_UPDATE_REQUEST,
// // 		})

// // 		const {
// // 			userLogin: { userInfo },
// // 		} = getState()

// // 		const config = {
// // 			headers: {
// // 				'Content-type': 'application/json',
// // 				Authorization: `Bearer ${userInfo.token}`,
// // 			},
// // 		}

// // 		const { data } = await axios.put(
// // 			`/api/experiences/${experience._id}`,
// // 			experience,
// // 			config
// // 		)

// // 		dispatch({
// // 			type: EXPERIENCE_UPDATE_SUCCESS,
// // 			payload: data,
// // 		})
// // 	} catch (error) {
// // 		dispatch({
// // 			type: EXPERIENCE_UPDATE_FAIL,
// // 			payload:
// // 				error.response && error.response.data.message
// // 					? error.response.data.message
// // 					: error.message,
// // 		})
// // 	}
// // }

// // export const createItineraryItem =
// // 	(experienceId, itineraryItem) => async (dispatch, getState) => {
// // 		try {
// // 			dispatch({
// // 				type: EXPERIENCE_CREATE_ITINERARYITEM_REQUEST,
// // 			})

// // 			const {
// // 				userLogin: { userInfo },
// // 			} = getState()

// // 			const config = {
// // 				headers: {
// // 					'Content-Type': 'application/json',
// // 					Authorization: `Bearer ${userInfo.token}`,
// // 				},
// // 			}

// // 			await axios.post(
// // 				`/api/experiences/${experienceId}/itinerary`,
// // 				itineraryItem,
// // 				config
// // 			)

// // 			dispatch({
// // 				type: EXPERIENCE_CREATE_ITINERARYITEM_SUCCESS,
// // 			})
// // 		} catch (error) {
// // 			const message =
// // 				error.response && error.response.data.message
// // 					? error.response.data.message
// // 					: error.message
// // 			if (message === 'Not authorized, token failed') {
// // 				dispatch(logout())
// // 			}
// // 			dispatch({
// // 				type: EXPERIENCE_CREATE_ITINERARYITEM_FAIL,
// // 				payload: message,
// // 			})
// // 		}
// // 	}

// // export const createTripOption =
// // 	(experienceId, tripOption) => async (dispatch, getState) => {
// // 		try {
// // 			dispatch({
// // 				type: EXPERIENCE_CREATE_TRIPOPTION_REQUEST,
// // 			})

// // 			const {
// // 				userLogin: { userInfo },
// // 			} = getState()

// // 			const config = {
// // 				headers: {
// // 					'Content-Type': 'application/json',
// // 					Authorization: `Bearer ${userInfo.token}`,
// // 				},
// // 			}

// // 			await axios.post(
// // 				`/api/experiences/${experienceId}/tripoption`,
// // 				tripOption,
// // 				config
// // 			)

// // 			dispatch({
// // 				type: EXPERIENCE_CREATE_TRIPOPTION_SUCCESS,
// // 			})
// // 		} catch (error) {
// // 			const message =
// // 				error.response && error.response.data.message
// // 					? error.response.data.message
// // 					: error.message
// // 			if (message === 'Not authorized, token failed') {
// // 				dispatch(logout())
// // 			}
// // 			dispatch({
// // 				type: EXPERIENCE_CREATE_TRIPOPTION_FAIL,
// // 				payload: message,
// // 			})
// // 		}
// // 	}

// // export const copyExperience = (id) => async (dispatch, getState) => {
// // 	try {
// // 		dispatch({
// // 			type: EXPERIENCE_CREATE_REQUEST,
// // 		})

// // 		const {
// // 			userLogin: { userInfo },
// // 		} = getState()

// // 		const config = {
// // 			headers: {
// // 				Authorization: `Bearer ${userInfo.token}`,
// // 			},
// // 		}

// // 		const { data } = await axios.post(`/api/experiences/${id}`, {}, config)

// // 		dispatch({
// // 			type: EXPERIENCE_CREATE_SUCCESS,
// // 			payload: data,
// // 		})
// // 	} catch (error) {
// // 		dispatch({
// // 			type: EXPERIENCE_CREATE_FAIL,
// // 			payload:
// // 				error.response && error.response.data.message
// // 					? error.response.data.message
// // 					: error.message,
// // 		})
// // 	}
// // }
