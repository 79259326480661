import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { Container } from 'react-bootstrap'

import { logout } from './services/actions/userActions'
import AuthVerify from './services/utils/AuthVerify'

import Header from './components/Header'
import SubHeader from './components/SubHeader'
import Footer from './components/Footer'
import HomeScreen from './screens/HomeScreen'
import ExperienceScreen from './screens/ExperienceScreen/ExperienceScreen'
import ReviewScreen from './screens/ReviewScreen'
import LoginScreen from './screens/LoginScreen'
import RegisterScreen from './screens/RegisterScreen'
import ProfileScreen from './screens/ProfileScreen'
import TravelerInfoScreen from './screens/TravelerInfoScreen'
import OrderOptionsScreen from './screens/OrderOptionsScreen'
import PaymentScreen from './screens/PaymentScreen'
import OrderScreen from './screens/OrderScreen'
import OrderListScreen from './screens/OrderListScreen'
import UserListScreen from './screens/UserListScreen'
import UserEditScreen from './screens/UserEditScreen'
import ExperienceListScreen from './screens/ExperienceListScreen'
import ExperienceEditDetailsScreen from './screens/ExperienceEditDetailsScreen'
import ExperienceEditAccScreen from './screens/ExperienceEditAccScreen'
import ExperienceEditItineraryScreen from './screens/ExperienceEditItineraryScreen'
import ExperienceListItineraryScreen from './screens/ExperienceListItineraryScreen'
import ExperienceEditTripOptionScreen from './screens/TripOption/ExperienceEditTripOptionScreen'
import ExperienceListTripOptionScreen from './screens/TripOption/ExperienceListTripOptionScreen'
import ExperienceEditDestinationScreen from './screens/ExperienceEditDestinationScreen'
import ExperienceListDestinationScreen from './screens/ExperienceListDestinationScreen'
import ExperienceLeadsScreen from './screens/ExperienceLeadsScreen/ExperienceLeadsScreen'
import ResetPasswordScreen from './screens/ResetPassword/ResetPasswordScreen'
import ForgotPasswordScreen from './screens/ForgotPassword/ForgotPasswordScreen'
import ExperienceListOrdersScreen from './screens/ExperienceListOrdersScreen'
import UserListOrdersScreen from './screens/UserListOrdersScreen'
import ScrollToTop from './components/ScrollToTop/ScrollToTop.js'
import { PayPalScriptProvider } from '@paypal/react-paypal-js'
import PublicOrgScreen from './screens/Organizations/PublicOrgScreen/PublicOrgScreen.js'
import OrgAdminNav from './components/OrgAdminNav/OrgAdminNav.js'
import MembershipReviewScreen from './screens/Organizations/MembershipFlow/MembershipReviewScreen/MembershipReviewScreen.js'
import MembershipOptionsScreen from './screens/Organizations/MembershipFlow/MembershipOptions/MembershipOptionsScreen.js'
import MemberInfoScreen from './screens/Organizations/MembershipFlow/MemberInfoScreen/MemberInfoScreen.js'
import MembershipPaymentScreen from './screens/Organizations/MembershipFlow/MembershipPaymentScreen/MembershipPaymentScreen.js'
import ErrorBoundary from './screens/ErrorBoundaryScreen/ErrorBoundaryScreen.js'
import NotFoundScreen from './screens/NotFoundScreen/NotFoundScreen.js'
import OrgListScreen from './screens/Organizations/OrgListScreen/OrgListScreen.js'
import OrgManageScreen from './screens/Organizations/OrgManageScreen/OrgManageScreen.js'

const App = () => {
	const PayPalClientId =
		process.env.NODE_ENV === 'production'
			? process.env.REACT_APP_PAYPAL_CLIENT_ID
			: process.env.REACT_APP_DEV_PAYPAL_CLIENT_ID
	return (
		<PayPalScriptProvider
			options={{
				'client-id': PayPalClientId,
				'disable-funding': 'credit',
				'enable-funding': 'venmo',
			}}
		>
			<ErrorBoundary>
				<Router>
					<ScrollToTop />
					<Header />
					<SubHeader />
					<OrgAdminNav />

					<Switch>
						<Route path='/' component={HomeScreen} exact />
						<Route path='/order/:id' component={OrderScreen} />
						<Route
							path='/org/:slug/:membershipprogramid/membership/:usermembershipid'
							component={MembershipPaymentScreen}
							exact
						/>
						<Route path='/login' component={LoginScreen} />
						<Route path='/travelerinfo' component={TravelerInfoScreen} />
						<Route path='/org/:slug/member-info' component={MemberInfoScreen} />
						<Route
							path='/:experienceid/options'
							component={OrderOptionsScreen}
						/>
						<Route
							path='/org/:slug/membership-options'
							component={MembershipOptionsScreen}
							exact
						/>
						<Route path='/payment' component={PaymentScreen} />
						<Route path='/register' component={RegisterScreen} />
						<Route path='/forgotPassword' component={ForgotPasswordScreen} />
						<Route path='/profile' component={ProfileScreen} />
						<Route
							path='/resetpassword/:resetToken'
							component={ResetPasswordScreen}
							exact
						/>
						<Route path='/admin/userlist' component={UserListScreen} exact />
						<Route
							path='/admin/userlist/search/:keyword'
							component={UserListScreen}
						/>
						<Route
							path='/admin/experiencelist'
							component={ExperienceListScreen}
						/>
						<Route path='/admin/orgList' component={OrgListScreen} />
						<Route path='/admin/orderlist' component={OrderListScreen} />
						<Route
							path='/admin/experience/:id/itinerary/:itinid'
							component={ExperienceEditItineraryScreen}
						/>
						<Route
							path='/admin/experience/:id/tripoption/:tripoptionid'
							component={ExperienceEditTripOptionScreen}
						/>
						<Route
							path='/admin/experience/:id/destination/:destid'
							component={ExperienceEditDestinationScreen}
						/>

						<Route path='/admin/user/:id/edit' component={UserEditScreen} />
						<Route path='/:id/review' component={ReviewScreen} />
						<Route
							path='/org/:slug/:membershipprogramid/review-membership'
							component={MembershipReviewScreen}
						/>

						<Route path='/home' component={HomeScreen} />
						<Route path='/home' component={HomeScreen} />
						<Route path='/experiences/:id' component={ExperienceScreen} exact />
						<Route path='/org/:slug' component={PublicOrgScreen} exact />

						<Route
							path='/admin/experience/:id/itinerary'
							component={ExperienceListItineraryScreen}
							exact
						/>
						<Route
							path='/admin/experience/:id/tripoption'
							component={ExperienceListTripOptionScreen}
							exact
						/>

						<Route
							path='/admin/experience/:id/destination'
							component={ExperienceListDestinationScreen}
							exact
						/>
						<Route
							path='/admin/experience/:id/details'
							component={ExperienceEditDetailsScreen}
							exact
						/>
						<Route path='/org/:slug/manage' component={OrgManageScreen} exact />
						<Route
							path='/admin/experience/:id/accommodations'
							component={ExperienceEditAccScreen}
							exact
						/>
						<Route
							path='/admin/experience/:id/orders'
							component={ExperienceListOrdersScreen}
							exact
						/>
						<Route
							path='/admin/experience/:id/leads'
							component={ExperienceLeadsScreen}
							exact
						/>
						<Route
							path='/admin/user/:id/orders'
							component={UserListOrdersScreen}
							exact
						/>

						<Route component={NotFoundScreen} />
					</Switch>
					<Footer />
					<AuthVerify logout={logout} />
				</Router>
			</ErrorBoundary>
		</PayPalScriptProvider>
	)
}

export default App
