import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, ListGroup, Card, Button, Container } from 'react-bootstrap'
import Message from '../../../components/Message'
import Loader from '../../../components/Loader'
import Meta from '../../../components/Meta'
import HeroImage from '../../../components/HeroImage'
import Gallery from '../../../components/Gallery'
import { getOrgDetails } from '../../../services/actions/orgActions'
import { getUserDetails } from '../../../services/actions/userActions'
import '../../../screens/ExperienceScreen/ExperienceScreen.css'

const PublicOrgScreen = ({ history, match }) => {
	const dispatch = useDispatch()
	const orgSlug = match.params.slug
	const [buttonLabel, setButtonLabel] = useState('Become a Member')
	const [hasActiveMemberships, setHasActiveMemberships] = useState(false)

	// Org details from state
	const { loading, error, org } = useSelector((state) => state.orgDetails)

	// User details from state
	const { user } = useSelector((state) => state.userDetails)

	useEffect(() => {
		if (!user?._id) {
			dispatch(getUserDetails('profile'))
		}
	}, [user, dispatch])

	useEffect(() => {
		dispatch(getOrgDetails(orgSlug))
	}, [orgSlug, dispatch])

	useEffect(() => {
		if (org && org.membershipPrograms) {
			const isActive = org.membershipPrograms.some(
				(program) => program.status === 'Active'
			)
			setHasActiveMemberships(isActive)
		}
	}, [org])

	useEffect(() => {
		const userMembership = user?.memberships?.find(
			(membership) => membership.org === org?._id
		)
		if (userMembership) {
			setButtonLabel(
				userMembership.status === 'Open'
					? 'Complete your membership'
					: 'View Membership'
			)
		} else {
			setButtonLabel('Become a Member')
		}
	}, [user, org?._id])

	const membershipButtonHandler = () => {
		const userMembership =
			user?.memberships?.find((membership) => membership.org === org?._id) ||
			null

		if (userMembership) {
			history.push(
				`/org/${orgSlug}/${userMembership.membershipProgram}/membership/${userMembership._id}`
			)
		} else {
			history.push(`/org/${orgSlug}/membership-options`)
		}
	}

	return (
		<>
			<Meta title={`I Do Tours | ${org?.name}`} />
			{loading ? (
				<Loader />
			) : error ? (
				<Message variant='danger'>{error}</Message>
			) : (
				<>
					<HeroImage image={org?.image} height='65vh' />
					<Container>
						<Row
							className='mb-4 mr-1 ml-1 mt-n5'
							style={{
								boxShadow: '0 0 10px #b0b0b0',
								backgroundColor: '#fff',
							}}
						>
							<Col xl={8} lg={7}>
								<ListGroup variant='flush'>
									<ListGroup.Item className='pt-3'>
										<h1>{org?.name}</h1>
										<h5>
											{org?.orgType} | {org?.locationName}
										</h5>
									</ListGroup.Item>
								</ListGroup>
							</Col>
							<Col xl={4} lg={5} className='p-0'>
								<Card className='h-100'>
									<ListGroup variant='flush' className='h-100'>
										<ListGroup.Item
											style={{
												display: 'flex',
												justifyContent: 'center',
												alignItems: 'center',
												height: '100%',
												padding: '10px',
											}}
										>
											<Button
												disabled={!hasActiveMemberships}
												onClick={membershipButtonHandler}
												className='btn-block'
												type='button'
												style={{
													width: '100%',
													maxWidth: '250px',
												}}
											>
												{buttonLabel}
											</Button>
										</ListGroup.Item>
									</ListGroup>
								</Card>
							</Col>
						</Row>

						<Row>
							<Col md={12}>
								<ListGroup variant='flush'>
									<ListGroup.Item>
										<h2>About our {org?.orgDesignation}</h2>
										{org?.aboutHeader}
									</ListGroup.Item>
								</ListGroup>
							</Col>
						</Row>
						<Gallery title={''} type='highlights' />
					</Container>
				</>
			)}
		</>
	)
}

export default PublicOrgScreen
